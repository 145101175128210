// -----------------------------------------------------------------------------
// Layer Popup
// -----------------------------------------------------------------------------

.layer {
  display: inline-block;
  position: relative;

  &-popup {
    position: absolute;
    z-index: 10;
    width: 400px;
    background: $white;
    padding: 20px;
    border: 1px solid $gray5;

    &[aria-hidden='true'] {
      display: none;
    }
  }

  &-h {
    display: inline-block;
  }

  &-body {
    font-size: 1.6rem;
  }

  &-close {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    color: $gray9;
    font-size: 30px;
    @include icon('close');
  }
}
