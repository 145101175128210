@font-face {
  font-family: 'icon';
  src: url('../font/icon.woff2') format('woff2'),
       url('../font/icon.woff') format('woff');
}

.icon {
@include icon-styles;
}

  .icon-arrow-down-bold {
    @include icon(arrow-down-bold, before, false);
  }
  .icon-arrow-down {
    @include icon(arrow-down, before, false);
  }
  .icon-arrow-left-bold {
    @include icon(arrow-left-bold, before, false);
  }
  .icon-arrow-left {
    @include icon(arrow-left, before, false);
  }
  .icon-arrow-right-bold {
    @include icon(arrow-right-bold, before, false);
  }
  .icon-arrow-right {
    @include icon(arrow-right, before, false);
  }
  .icon-arrow-up-bold {
    @include icon(arrow-up-bold, before, false);
  }
  .icon-arrow-up {
    @include icon(arrow-up, before, false);
  }
  .icon-backtotop {
    @include icon(backtotop, before, false);
  }
  .icon-check {
    @include icon(check, before, false);
  }
  .icon-close {
    @include icon(close, before, false);
  }
  .icon-download {
    @include icon(download, before, false);
  }
  .icon-error {
    @include icon(error, before, false);
  }
  .icon-locknlockmall {
    @include icon(locknlockmall, before, false);
  }
  .icon-new-window {
    @include icon(new-window, before, false);
  }
  .icon-pause {
    @include icon(pause, before, false);
  }
  .icon-play-round {
    @include icon(play-round, before, false);
  }
  .icon-play {
    @include icon(play, before, false);
  }
  .icon-plus {
    @include icon(plus, before, false);
  }
  .icon-refresh {
    @include icon(refresh, before, false);
  }
  .icon-search {
    @include icon(search, before, false);
  }
  .icon-sns-blog {
    @include icon(sns-blog, before, false);
  }
  .icon-sns-facebook {
    @include icon(sns-facebook, before, false);
  }
  .icon-sns-instagram {
    @include icon(sns-instagram, before, false);
  }
  .icon-sns-youtube {
    @include icon(sns-youtube, before, false);
  }
  .icon-sound {
    @include icon(sound, before, false);
  }
  .icon-success {
    @include icon(success, before, false);
  }
