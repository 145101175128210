// -----------------------------------------------------------------------------
// Form > img-upload
// -----------------------------------------------------------------------------

.img-upload {
  display: flex;
  flex-wrap: wrap;

  .form-g {
    width: 100%;
    margin-top: 0;
  }

  &__thumb {
    box-sizing: border-box;
    width: calc(46% - #{rem(20)});
    max-width: 180px;
    margin-top: rem(10);
    margin-right: rem(20);
    border: 1px solid $gray2;

    .thumb {
      overflow: hidden;
      position: relative;
      display: block;
      // 반명함 비율(3*4) 유지
      padding-top: percentage(4/3);

      // width에 맞춰 이미지를 축소
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__cont {
    box-sizing: border-box;
    width: 54%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ul-dash {
      font-size: rem(15);
      color: $gray6;
    }
  }

  &__func {
    margin-top: rem(10);

    // label btn focus
    input[type='file']:focus {
      & + label {
        text-decoration: none;
        color: $white;

        &::before {
          transform: scale(1, 1);
          transform-origin: left center;
        }
      }
    }
  }

  @include mq(large) {
    box-sizing: border-box;
    display: block;
    width: 23.5%;
    padding-right: 4.5%;

    &__thumb {
      width: 100%;
      max-width: none;
      margin-top: 0;
    }

    &__cont {
      width: 100%;
    }

    &__func {
      .btn {
        width: 100%;
      }
    }
  }
}
