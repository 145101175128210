// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $gray9;
  font-family: $font-sub-kor;
  font-weight: 700;
  line-height: 1.2;
}

.h1 { font-size: rem(46); }
.h2 { font-size: rem(36); }
.h3 { font-size: rem(32); }
.h4 { font-size: rem(28); }
.h5 { font-size: rem(24); }
.h6 { font-size: rem(20); }

.body1 { font-size: rem(20); }
.body2 { font-size: rem(18); }
.body3 { font-size: rem(16); }

.caption {
  font-size: rem(16);
  color: $gray6;
  line-height: 1.4;
}

.desc {
  font-size: rem(16);
  line-height: 1.4;
}

@include mq(medium) {
  @media (min-height: 500px) {
    .h1 { font-size: rem(88); }
    .h2 { font-size: rem(68); }
    .h3 { font-size: rem(50); }
    .h4 { font-size: rem(42); }
    .h5 { font-size: rem(28); }

    .body1 { font-size: rem(24); }
    .body2 { font-size: rem(20); }
    .body3 { font-size: rem(18); }
  }
}
