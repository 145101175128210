// -----------------------------------------------------------------------------
// Form > Form Group
// -----------------------------------------------------------------------------

.form-g {
  margin-top: rem(25);

  & + & {
    margin-top: rem(20);
  }

  &.is-success {
    input,
    select,
    textarea,
    .dropdown-selector,
    .ta-counter-box {
      border-color: $color-secondary;
    }

    small {
      display: block;
      font-size: 13px;
      margin-top: 7px;
      color: $color-secondary-dark;

      @include icon('success', before) {
        margin-right: 5px;
        vertical-align: middle;
        font-size: 15px;
        color: $color-secondary;
      }
    }
  }

  &.is-error {
    input,
    select,
    textarea,
    .dropdown-selector,
    .ta-counter-box {
      border-color: $color-error;
    }

    small {
      display: block;
      font-size: 13px;
      margin-top: 7px;
      color: $color-error;

      @include icon('error', before) {
        margin-right: 5px;
        vertical-align: middle;
        font-size: 15px;
        color: $color-error;
      }
    }
  }

  &.is-required {
    .label {
      &::after {
        content: '*';
        display: inline-block;
        margin-left: rem(3);
      }
    }
  }

  .dropdown {
    .dropdown-popup {
      display: block;
      opacity: 0;
      z-index: -1 !important;
    }

    &.is-opened {
      .dropdown-popup {
        opacity: 1;
        z-index: 101 !important;
      }
    }
  }

  &--icon {
    display: flex;
    align-items: center;

    .i,
    .checkbox {
      display: inline-block;
      margin-left: rem(8);
      font-size:rem(15);
      font-style: normal;
      white-space: nowrap;
    }
  }

  &--right {
    margin-left: auto !important;
  }

  @include mq(medium) {
    &--sm {
      max-width: 320px;
    }
  }

  @include mq(large) {
    margin-top: rem(20);

    & + & {
      margin-top: rem(24);
    }
  }
}
