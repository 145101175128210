// -----------------------------------------------------------------------------
// Header
// -----------------------------------------------------------------------------
$logo-h-sm: 18px;
$logo-w-sm: 120px;
$logo-h-lg: 20px;
$logo-w-lg: 134px;

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: $header-zindex;
  transition: transform $dur $ease, background-color $dur $ease;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: rgba($gray9, 0.2);
    opacity: 0;
  }

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: inline-block;
  }

  &__link {
    display: flex;
    align-items: center;
    height: $header-height-sm;
  }

  &__svg {
    path {
      fill: $white;
    }
  }

  &__pad {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &__util {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: $header-height-sm;
    padding: rem(10) rem(8);
    color: $white;

    .icon-search {
      font-size: rem(24);
    }

    &--search-close {
      visibility: hidden;
      position: absolute;
      right: -13px;
      top: 0px;
      font-size: rem(25);
      z-index: 1;
    }
  }

  &__search {
    position: fixed;
    top: $header-height-sm;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    // transition: transform $dur $ease;
    // transform: translate(-100%, 0);
    display: none;
  }

  // variation
  &.has-bg {
    background-color: $white;

    &::before {
      opacity: 1;
    }

    .header {
      &__svg {
        path {
          fill: $gray9;
        }
      }

      &__lang {
        .lang__current {
          background-color: transparent;
          color: $gray9;
        }
      }

      &__icon {
        color: $gray9;
      }

      &__toggle {
        .bar {
          background-color: $gray9;
        }
      }
    }
  }

  &.is-rollup {
    transform: translateY(-100%);
  }

  &.is-active-search {
    .header {
      &__search {
        display: block;
        // transform: translate(0%, 0%);
      }

      &__icon {
        &--search-close {
          visibility: visible;
        }

        &--search {
          visibility: hidden;
        }
      }

      &__toggle {
        visibility: hidden;
      }
    }
  }

  @include mq($until: large) {
    height: $header-height-sm;

    &__svg {
      width: $logo-w-sm;
    }

    &__util {
      right: 0;
    }

    &__toggle {
      box-sizing: border-box;
      display: block;
      height: $header-height-sm;
      padding: rem(10);
      margin-right: rem(-10);

      .bar {
        display: block;
        width: 20px;
        height: 2px;
        background-color: $white;

        & + .bar {
          margin-top: rem(6);
        }
      }
    }

    &.is-active {
      .header {
        &__icon {
          &--search {
            display: none;
          }
        }
      }
    }
  }

  @include mq(medium, $until: large) {
    &.is-active,
    &.is-active-search {
      box-sizing: border-box;
      width: 60%;
      max-width: 490px;
    }

    .header {
      &__search {
        width: 60%;
        max-width: 490px;
      }
    }
  }

  @include mq(large) {
    height: $header-height-lg;
    transition: transform $dur $ease, background-color $dur $ease;

    .gnb {
      .d1__item {
        color: $white;
      }
    }

    &__link {
      height: $header-height-lg;
    }

    &__svg {
      width: $logo-w-lg;
    }

    &__util {
      right: -12px;
    }

    &__lang {
      position: relative;
      margin-right: rem(0);
      font-family: $font-sub-kor;
      font-size: rem(15);
      font-weight: 700;

      [lang='en'] & {
        font-family: $font-main-eng;
      }

      .lang {
        &__current,
        &__item {
          box-sizing: border-box;
          display: block;
          min-width: 110px;
          padding: 0 30px;
          text-align: center;
          line-height: 1;
        }

        &__current {
          position: relative;
          padding-top: 24.5px;
          padding-bottom: 24.5px;
          color: $white;

          @include icon('arrow-down-bold') {
            position: absolute;
            top: 50%;
            right: 18px;
            font-size: rem(18);
            transform: translateY(-50%);
            pointer-events: none;
            transition: transform $dur $ease;
          }
        }

        &__item {
          padding-top: 24.5px;
          padding-bottom: 24.5px;
          color: $gray3;
          background-color: $white;

          &:hover,
          &:focus {
            color: $gray9;
            transition: color $dur $ease;
            background-color: $white;
          }
        }

        &__option {
          display: none;
          position: absolute;
          // left: 20px;
          bottom: 0;
          transform: translateY(100%);
          border: 1px solid rgba($gray9, 0.2);
          border-top: none;
        }
      }

      &.is-opened {
        .lang {
          &__current {
            &::before {
              transform: translateY(-50%) rotate(-180deg);
            }
          }

          &__option {
            display: block;
          }
        }
      }
    }

    &__icon {
      &--search-close {
        line-height: 1;
        top: 0;
        right: 0;
        height: $header-height-lg;
      }
    }

    &__search {
      position: fixed;
      top: 0;
      bottom: auto;
      height: auto;
      padding-bottom: rem(60);
      border-bottom: 1px solid rgba($gray9, 0.2);
      // transform: translate(0, -100%);

      &::before {
        content: '';
        position: absolute;
        top: $header-height-lg;
        width: 100%;
        height: 1px;
        background-color: rgba($gray9, 0.2);
        z-index: 1;
      }
    }

    &.has-bg {
      .d1__item {
        color: $gray9;
      }

      .sub-wrap {
        background-color: rgba($white, 1);
      }
    }
  }
}
