// -----------------------------------------------------------------------------
// List
// -----------------------------------------------------------------------------
.ul,
.ul-dash,
.ul-noti,
.ol,
.ol-kor,
.ol-bracket,
.ol-bracket-kor {
  > li {
    position: relative;
  }

  li {
    @include break-word;

    p {
      margin: 0.5em 0;
    }
  }

  &.is-narrow {
     > li {
       margin-top: 0.05em;
     }
  }
}

// unordered
// -----------------------------------------------------------------------------
.ul,
.ul-dash,
.ul-noti {
  > li {
    margin-top: 0.5em;

    > ul,
    > ol {
      margin-left: rem(-12);
    }
  }
}

.ul {
  > li {
    padding-left: rem(18);

    &::before {
      content: '';
      position: absolute;
      top: 0.8em;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $gray-dark;
    }
  }
}

.ul-dash {
  > li {
    padding-left: rem(10);

    &::before {
      content: '';
      position: absolute;
      top: 0.9em;
      left: 0;
      width: 5px;
      height: 1px;
      background-color: $gray9;
    }
  }
}

.ul-noti {
  margin-bottom: 1em;

  > li {
    padding-left: rem(20);

    &::before {
      content: '※';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// ordered
// -----------------------------------------------------------------------------

$order-list-counter: order-list-counter;
// $hex-numbers: (
//   0: 0,
//   1: 1,
//   2: 2,
//   3: 3,
//   4: 4,
//   5: 5,
//   6: 6,
//   7: 7,
//   8: 8,
//   9: 9,
//   10: 'A',
//   11: 'B',
//   12: 'C',
//   13: 'D',
//   14: 'E',
//   15: 'F',
// );

$kor-list: '가', '나', '다', '라', '마', '바', '사', '아', '자', '차', '카',
  '타', '파', '하';

.ol,
.ol-bracket,
.ol-kor,
.ol-bracket-kor {
  counter-reset: $order-list-counter;

  > li {
    margin-top: 1em;

    // 중첩 리스트 스타일
    > ul,
    > ol {
      margin-bottom: 1em;
      margin-left: 0.2em;

      & > li {
        &:first-child {
          margin-top: 0.5em;
        }
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.ol {
  > li {
    padding-left: 1.2em;

    &::before {
      counter-increment: $order-list-counter;
      content: counter($order-list-counter) '.';
    }
  }
}

.ol-bracket {
  > li {
    padding-left: 1.2em;

    &::before {
      counter-increment: $order-list-counter;
      content: counter($order-list-counter) ')';
    }
  }
}

.ol-kor {
  > li {
    padding-left: 1.4em;

    @each $kor in $kor-list {
      $i: index($kor-list, $kor);

      &:nth-child(#{$i}) {
        &::before {
          content: $kor + '.';
        }
      }
    }
  }
}

.ol-bracket-kor {
  > li {
    padding-left: 1.4em;

    @each $kor in $kor-list {
      $i: index($kor-list, $kor);

      &:nth-child(#{$i}) {
        &::before {
          content: $kor + ')';
        }
      }
    }
  }
}

// .ol-round {
//   > li {
//     &::before {
//       position: absolute;
//       top: 0;
//       left: 0;
//     }

//     @for $i from 1 through 16 {
//       &:nth-child(#{$i}) {
//         &::before {
//           content: unicode('246' + quote(map-get($hex-numbers, ($i - 1))));
//         }
//       }
//     }
//     @for $i from 17 through 20 {
//       &:nth-child(#{$i}) {
//         &::before {
//           content: unicode('247' + quote(map-get($hex-numbers, ($i - 17))));
//         }
//       }
//     }
//   }
// }

// Description List
// -----------------------------------------------------------------------------
// .dl-normal {
//   dt {
//     font-weight: bold;
//   }
//   dd {
//     margin-bottom: 20px;
//   }
// }

// .dl-bar {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;

//   dt,
//   dd {
//     margin-bottom: 5px;
//   }
//   dt {
//     position: relative;
//     flex-basis: 20%;

//     &::after {
//       content: '';
//       position: absolute;
//       top: 8px;
//       right: 10px;
//       display: inline-block;
//       width: 1px;
//       height: 12px;
//       background: $gray3;
//     }
//   }

//   dd {
//     flex-basis: 80%;
//   }
// }
