// -----------------------------------------------------------------------------
// Form > Form Row (복잡한 형태의 Form)
// -----------------------------------------------------------------------------

.form-row {
  display: flex;
  flex-wrap: wrap;

  & + & {
    .label {
      margin-top: rem(22);
    }
  }

  .label {
    .form & {
      margin-bottom: 0;
    }
  }

  .btn {
    padding-left: 0;
    padding-right: 0;
  }

  .messages {
    width: 100%;
  }

  &--gap {
    margin-bottom: rem(3);
  }

  @include mq($until:large) {
    .form-g {
      width: 100%;
      margin-top: rem(10);
    }

    [class*='form-g-sm'] {
      & +  [class*='form-g-sm'] {
        margin-left: rem(10);
      }
    }

    .form-g-sm {
      &--w30 { width: calc(30% - 5px); }
      &--w40 { width: calc(40% - 5px); }
      &--w50 { width: calc(50% - 5px); }
      &--w60 { width: calc(60% - 5px); }
      &--w70 { width: calc(70% - 5px); }
    }
  }

  @include mq(large) {
    & + & {
      margin-top: rem(10);
    }

    .label {
      .form & {
        margin-bottom: rem(10);
      }
    }

    .messages {
      .help-block {
        margin-right: rem(15);
      }
    }

    &--gap {
      margin-top: rem(35);
      margin-bottom: rem(10);
    }

    .form-g {
      margin-top: 0;
      width: 100%;

      &:first-child {
        margin-top: 0;
      }

      & + .form-g {
        margin-left: rem(10);
      }
    }

    .form-g-lg {
      &--w10 { width: 10%; }
      &--w15 { width: 15%; }
      &--w20 { width: 20%; }
      &--w25 { width: 25%; }
      &--w30 { width: 30%; }
      &--w40 { width: 40%; }
      &--w50 { width: 50%; }
      &--w60 { width: 60%; }
      &--w70 { width: 70%; }
      &--w75 { width: 75%; }
      &--w80 { width: 80%; }
    }

    @for $i from 2 through 5 {
      $gap: 10 * ($i - 1) / $i;

      &--#{$i} {
         .form-g-lg {
          &--w10 { width: calc(10% - #{$gap}px); }
          &--w15 { width: calc(15% - #{$gap}px); }
          &--w20 { width: calc(20% - #{$gap}px); }
          &--w25 { width: calc(25% - #{$gap}px); }
          &--w30 { width: calc(30% - #{$gap}px); }
          &--w40 { width: calc(40% - #{$gap}px); }
          &--w50 { width: calc(50% - #{$gap}px); }
          &--w60 { width: calc(60% - #{$gap}px); }
          &--w70 { width: calc(70% - #{$gap}px); }
          &--w75 { width: calc(75% - #{$gap}px); }
          &--w80 { width: calc(80% - #{$gap}px); }
        }
      }
    }
  }
}
