.notibox {
  position: relative;
  border-top: 1px solid $gray2;
  border-bottom: 1px solid $gray2;
  padding: rem(10) rem(10) rem(10) 0;

  &__desc {
    position: relative;
    margin: 0;
    padding-left: rem(37);
    color: $gray6;
    font-size: rem(16);

    @include icon('error') {
      position: absolute;
      top: 0;
      left: 10px;
      color: $gray9;
    }
  }

  @include mq(medium) {
    box-sizing: border-box;
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
  }
}
