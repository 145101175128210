// -----------------------------------------------------------------------------
// Common
// -----------------------------------------------------------------------------

.flexible-obj {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  // object,
  // embed,
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.func {
  text-align: center;
}

.ta-c {
  text-align: center;
}
.ta-r {
  text-align: right;
}

.bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__img,
  &__video,
  img {
    // img 요소는 picture일 경우 대비
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__img-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &--dimmed {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#000, 0.5);
    }
  }

  .no-objectfit & {
    &__img,
    &__video,
    img {
      @include objectfit-polyfill;
    }
  }
}

.reveal {
  opacity: 0;
}

.reveal-bg {
  overflow: hidden;

  .bg {
    opacity: 0;
    transform: scale(1.09);
  }
}

#backdrop {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $header-zindex - 10;
  opacity: 0;
  transition: opacity 0.1s $ease;
  cursor: pointer;

  &.is-active {
    width: 100%;
    height: 100%;
    background-color: rgba($gray2, 0.1);
    backdrop-filter: blur(3px);
    opacity: 1;
  }
}

.is-full {
  // scroll 제거
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */

  // &::-webkit-scrollbar {
  //   display: none; /* Chrome, Safari, Opera*/
  // }
}

body {
  &.is-dimmed {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $header-zindex + 10;
      background-color: rgba($gray9, 0.2);
    }
  }
}
