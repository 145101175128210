// -----------------------------------------------------------------------------
// no-result (결과 없음)
// -----------------------------------------------------------------------------

.no-result {
  margin-bottom: rem(80);
  padding: rem(60) 0;
  text-align: center;
  border-top: 2px solid $gray9;
  border-bottom: 1px solid $gray2;

  p {
    margin: 0;
  }

  &__icon {
    margin-bottom: rem(16);

    @include icon('error') {
      color: rgba($gray9, 0.8);
      font-size: rem(25);
      line-height: 1;
    }
  }

  &--noline {
    border: none;
    margin-bottom: 0;
  }

  @include mq(large) {
    margin-bottom: rem(120);
    padding: rem(120) 0;

    &--noline {
      margin-bottom: 0;
    }
  }
}
