// -----------------------------------------------------------------------------
// Variable
// -----------------------------------------------------------------------------

// breakpoints
$mq-breakpoints: (
  small: 560px,
  medium: 720px,
  large: 960px,
  x-large: 1200px,
  xx-large: 1600px,
);

// grid
// -----------------------------------------------------------------------------
$gutter-sm: 10px;
$gutter-lg: 20px;
$grid-columns-sm: 6;
$grid-columns-lg: 12;

// layout
// -----------------------------------------------------------------------------
$w-small: 360px;
$w-default: 1130px;
$w-wrap: 1360px;
$w-narrow: 670px;

$offset-sm: 20px;
$offset-md: 46px;
$offset-lg: 40px;

$header-height-sm: 60px; // 모바일 header 높이
$header-height-lg: 64px; // 데스크탑 header 높이

$lnb-height-sm: 60px; // 데스크탑 lnb 높이
$lnb-height-lg: 64px; // 데스크탑 lnb 높이
// $header-height-compact: 45px; // 데스크탑 header 최소화 시 높이

$header-zindex: 100;

$input-padding-tb: 15px;
$input-padding-lr: 15px;

// fonts
// -----------------------------------------------------------------------------
$base-font-size: 10; // function rem()

$font-list-kor: (
  /* [filename]: ([font-family]: [weight])*/
  NotoSans-Bold: (NotoSans, 700),
  NotoSans-Regular: (NotoSans, 400),
  Roboto-kor-Bold: (Roboto, 700),
  Roboto-kor-Medium: (Roboto, 500),
  Roboto-kor-Regular: (Roboto, 400)
);

$font-list-eng: (
  /* [filename]: ([font-family]: [weight])*/
  Roboto-eng-Bold: (Roboto, 700),
  Roboto-eng-Medium: (Roboto, 500),
  Roboto-eng-Regular: (Roboto, 400)
);


// 'Malgun Gothic', '맑은 고딕',
// '돋움', dotum
$font-main-kor: 'NotoSans', sans-serif;
$font-sub-kor: 'Roboto', 'NotoSans', sans-serif;

$font-main-eng: 'Roboto', sans-serif;

$font-icon: 'icon';
$icons: ();

// color
// -----------------------------------------------------------------------------
@import '../color.json';

// 리스트 공통
// -----------------------------------------------------------------------------
$list-bg-color: $gray1;
$list-border-color: $gray2;
$list-border-dark-color: $gray7;
$list-padding-lr: 15px;

$element-margin-lr: 0.5em;

// 애니메이션
// -----------------------------------------------------------------------------
$dur: 0.4s;
$ease: cubic-bezier(0.25, 0.45, 0.5, 1);
