// -----------------------------------------------------------------------------
// Video Box
// -----------------------------------------------------------------------------

.video-box {
  position: relative;

  &-player {
    cursor: pointer;
  }

  &-play {
    @include full-pos;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(40);
    // pointer-events: none;
    width: 100%;
    z-index: 10;

    &::before {
      content: '';
      @include full-pos;
      background-color: rgba($gray9, 0.2);
    }

    @include icon('play-round', after) {
      color: $white;
      z-index: 1;
    }
  }

  &.is-play {
    .video-box-play {
      display: none;
    }
  }

  &-subtitle {
    position: relative;
    display: none;
    background-color: $gray1;

    &.is-reveal {
      display: block;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: calc(100% - 7px);
      height: rem(20);
      background-color: $gray1;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }
  }

  @include scrollbars(
    '.video-box-pad',
    7px,
    darken($silver-light, 5%),
    lighten($silver-light, 10%)
  );

  &-pad {
    height: 280px;
    overflow-y: scroll;
    padding: rem(20) rem(25);
    font-size: rem(16);
    color: $gray6;

    p {
      margin: 0;

      & + p {
        margin-top: rem(15);
      }
    }
  }

  .yt-player,
  .video-player {
    // transition: $dur $ease;
  }

  &:hover,
  &:focus {
    .yt-player,
    .video-player {
      // transform: scale(1.1);
    }
  }

  @include mq(large) {
    &-play {
      font-size: rem(80);
    }

    &-pad {
      padding-left: percentage(1/5);
      padding-right: percentage(1/5);
      height: 180px;
    }
  }
}
