.lnb {
  .lnb-wrap & {
    max-height: none;
  }


  &-wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: $header-height-sm;
    z-index: $header-zindex - 20;
    box-sizing: border-box;
    height: $lnb-height-sm;
    background-color: $white;
    transition: transform $dur $ease;

    &.is-rollup {
      transform: translateY(-100%);

      &--hide {
        transform: translateY(-200%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba($gray9, 0.2);
    }
  }

  &-selector {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 18px 0;
    text-align: left;
    min-height: $lnb-height-sm;

    @include icon('arrow-down-bold') {
      position: absolute;
      top: 22px;
      right: 0;
      font-size: rem(18);
      transition: transform $dur $ease;

      .is-opened & {
        transform: rotate(-180deg);
      }
    }
  }

  &-value {
    font-weight: 700;
  }

  &__link {
    display: block;
  }

  &__text {
    text-transform: capitalize;
    pointer-events: none;
    // white-space: pre;
  }

  @include mq($until: large) {
    &-options {
      display: none;
      padding: rem(14) 0;

      @include full-deco('bg', $white);
      @include full-deco('border', rgba($gray9, 0.2), 'before') {
        top: auto;
        bottom: 0;
      }
    }

    &.is-opened {
      .lnb-options {
        display: block;
      }
    }

    &__link {
      padding: rem(6) 0;
    }

    &__text {
      .is-selected & {
        border-bottom: 1px solid $gray9;
      }
    }
  }

  @include mq(large) {
    &-wrap {
      top: $header-height-lg;
      height: $lnb-height-lg;
    }

    &-selector {
      display: none;
    }

    &-options {
      display: flex;
      font-size: rem(17);
      margin: 0 rem(-20);
    }

    &__item {
      position: relative;
      transition: textShadow $dur $ease;

      &.is-selected,
      &:hover,
      &:focus {
        text-shadow: 0 0;
      }
    }

    &__link {
      padding: rem(18.5) rem(20);
    }
  }

  @include mq(large, $until: x-large) {
    [lang='en'] & {
      &__text {
        font-size: rem(14);
      }
    }
  }
}

#lnb-line {
  @include mq(large) {
    position: absolute;
    bottom: 0px;
    display: block;
    height: 3px;
    background-color: $gray9;
    width: 0;
    pointer-events: none;
  }
}
