// -----------------------------------------------------------------------------
// Layout Components
// -----------------------------------------------------------------------------

.root {
  box-sizing: border-box;
  outline: none; // for skipnav
  color: $gray9;
  font-size: rem(16);
  line-height: 1.6;
  font-weight: 400;
  outline: none;
  // min-height: 100vh;
  // padding-top: $header-height-sm;

  @include mq(large) {
    font-size: rem(18);
  }
}

// breakpoint
#viewport-breakpoint {
  $bp-names: '';
  $index: 1;
  position: relative;
  display: none;
  // speak: none;
  z-index: $index;

  @each $name, $point in $mq-breakpoints {
    $index: $index + 1;
    $bp-names: $bp-names + $name + ',';

    @include mq($name) {
      z-index: $index;
    }
  }

  content: str-slice($bp-names, 0, str-length($bp-names) - 1);
}

// skip navigation
#skipnav {
  position: absolute;
  left: -100%;
  color: $white;
  background: $gray;
  font-size: rem(16);

  &:focus {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 29999;
    padding: 20px;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    // vertical-align: middle;
  }
}
