.form-agree {
  padding-top: rem(10);

  &__item {
    position: relative;

    & + & {
      border-top: 1px solid $gray2;
    }

    &.is-active {
      .form-agree {
        &__btn {
          &::before {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  &__h {
    padding: rem(12) 0;

    &.form-g {
      margin-top: 0;
    }
  }

  &__checkbox {
    width: calc(100% - 60px);

    .checkbox {
      &__text {
        font-size: rem(16);
      }
    }
  }

  &__btn {
    position: absolute;
    top: 0;
    right: 4px;
    display: inline-block;
    width: 49px;
    height: 49px;
    @include icon('arrow-down-bold') {
      transition: transform $dur $ease;
      margin-top: 5px;
      margin-left: 26px;
    }
  }

  @include scrollbars('.form-agree__cont', 4px, $gray2, $gray0);

  &__cont {
    box-sizing: border-box;
    overflow-y: scroll;
    height: 143px;
    margin-bottom: rem(20);
    padding: rem(10) rem(15);
    border: 1px solid $gray2;
    background-color: $white;
    color: $gray6;
    font-size: rem(15);

    .h {
      display: block;
      margin: 2.2em 0 1.6em;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      margin: 1.6em 0;
    }
  }

  @include mq(large) {

    &__h {
      padding: rem(15) 0;
    }

    &__btn {
      width: 58px;
      height: 58px;

      &::before {
        margin-top: 8px;
        margin-left: 35px;
      }
    }

    &__cont {
      // margin-top: rem(-9);
    }
  }
}
