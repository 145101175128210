// -----------------------------------------------------------------------------
// Grid
// -----------------------------------------------------------------------------

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-sm / 2;
  margin-right: -$gutter-sm / 2;

  &.has-no-gutter {
    margin-left: 0;
    margin-right: 0;

    [class*=col-] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

[class*=col-] {
  box-sizing: border-box;
  max-width: 100%;
  min-height: 1px;
  flex-shrink: 0;
  padding-left: $gutter-sm / 2;
  padding-right: $gutter-sm / 2;
}

@include mq($until: large) {
  [class*=col-lg-] {
    width: 100%;
  }
}
@include mq($until: medium) {
  [class*=col-md-] {
    width: 100%;
  }
}
@include mq($until: small) {
  [class*=col-sm-] {
    width: 100%;
  }
}

.col-align-center {
  display: flex;
  align-items: center;
}

// default grid
@for $i from 1 through $grid-columns-lg {
  .col-#{$i} {
    width: (100% * #{$i}) / #{$grid-columns-lg};
  }
}


// small -sm-
// -----------------------------------------------------------------------------
@for $i from 1 through $grid-columns-sm {
  .col-sm-#{$i} {
    width: (100% * #{$i}) / #{$grid-columns-sm};
  }
}


// medium -md-
// -----------------------------------------------------------------------------
@include mq(medium) {
  .row {
    margin-left: -$gutter-lg / 2;
    margin-right: -$gutter-lg / 2;
  }

  [class*=col-] {
    padding-left: $gutter-lg / 2;
    padding-right: $gutter-lg / 2;
  }
}


// large -lg-
// -----------------------------------------------------------------------------
@include mq(large) {
  @for $i from 1 through $grid-columns-lg {
    .col-lg-#{$i} {
      width: (100% * #{$i}) / #{$grid-columns-lg};
    }
  }
  @for $i from 1 through ($grid-columns-lg - 1) {
    .col-lg-push-#{$i} {
      margin-left: (100% * #{$i}) / #{$grid-columns-lg};
    }
  }
}
