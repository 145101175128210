// -----------------------------------------------------------------------------
// Dropdown list (switching)
// -----------------------------------------------------------------------------

.dropdown-list {
  $height: 20px;

  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: left;

  @include mq($until: medium) {
    @include scrollbars('.dropdown-list-options', 7px, darken($silver-light, 5%), lighten($silver-light, 10%));

    &-selector {
      box-sizing: border-box;
      width: 100%;
      padding: $input-padding-tb $input-padding-lr;
      padding-right: $input-padding-lr + 20px;
      border: 1px solid $silver;

      background: $white;
      color: $gray-dark;
      line-height: $height;
      text-align: left;

      outline: none;
      vertical-align: middle;
      transition: border-color 0.3s;
      @include icon('arrow-up', before);
      @include icon('arrow-down', after);

      &::before,
      &::after {
        position: absolute;
        top: 14px;
        right: 15px;
        font-size: 16px;
      }

      &::before {
        display: none;
      }

      .no-touchevents & {

        &:hover,
        &:focus {
          border-color: $gray9;
        }
      }
    }

    &-value {
      position: relative;
      display: block;
      height: $height;
      @include ellipsis(1);
      @include break-word();
    }

    &-popup {
      position: absolute;
      left: 0;
      right: 0;
      display: none;
      overflow: hidden;
      margin-top: -1px;
      z-index: 1;
      transition: all 0.3s;
    }

    &-options {
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      border: 1px solid $silver;
      background: $white;
      max-height: 270px;

      >li {
        position: relative;
        min-height: $height + ($input-padding-tb * 2);

        >a {
          box-sizing: border-box;
          display: block;
          float: none;
          padding: $input-padding-tb $input-padding-lr;
          padding-right: $input-padding-lr * 2;
          text-align: left;
          line-height: $height;
          text-decoration: none;

          &:hover,
          &:focus {
            outline: none;
            background: $gray;
            color: $white;
          }
        }


        &.is-selected {
          >a {
            color: $color-primary;

            &:hover,
            &:focus {
              background: transparent;
            }
          }

          // @include icon('select', before) {
          //   position: absolute;
          //   font-size: 12px;
          //   top: 50%;
          //   right: 15px;
          //   margin-top: -8px;
          //   color: $color-primary-light;
          // }
        }

        &.is-disabled {

          >a,
          >label {
            cursor: not-allowed;
            color: $silver;

            &:hover,
            &:focus {
              background: transparent;
            }
          }
        }
      }
    }

    &.is-opened {
      .dropdown-list {
        &-selector {
          border-color: $dark;

          &::before {
            display: block;
          }

          &::after {
            display: none;
          }
        }

        &-popup {
          display: block;
        }

        &-options {
          display: block;
          border-color: $dark;
        }
      }
    }
  }

  @include mq(medium) {
    &-selector {
      display: none;
    }

    &-options {
      >li {
        @include bar-list(25px, 2px, 16px);

        >a {
          color: $gray;

          &:hover,
          &:focus {
            color: $color-primary;
          }
        }

        &.is-selected {
          >a {
            color: $color-primary;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
