// -----------------------------------------------------------------------------
// back-to-top
// -----------------------------------------------------------------------------
.back-to-top {
  $size: 40px;

  position: absolute;
  top: -13px;
  right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $size;
  height: $size;
  background-color: $gray9;
  transition: 0.3s $ease;

  .icon-backtotop {
    color: $white;
    font-size: rem(16);
  }

  @include mq($until: large) {
    right: 0;
  }

  .no-touchevents & {
    &:hover,
    &:focus {
      background-color: $color-secondary;
    }
  }
}
