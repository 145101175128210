// -----------------------------------------------------------------------------
// Footer
// -----------------------------------------------------------------------------
$logo-h-sm: 18px;
$logo-w-sm: 120px;
$logo-h-lg: 20px;
$logo-w-lg: 134px;

.footer {
  overflow: hidden;
  padding-top: rem(40);
  border-top: 1px solid rgba($gray9, 0.2);
  color: $gray6;
  font-family: $font-sub-kor;
  font-size: rem(14);
  line-height: 1.4;

  [lang='en'] & {
    font-family: $font-main-eng;
  }
}

.footer-logo {
  &__link {
    display: inline-block;
  }
  &__img {
    height: 100%;
  }
}

.footer-nav {
  .sub-wrap {
    @include full-deco('bg', $gray1);
  }
  .d2-list {
    position: relative;
    left: -$offset-sm;
    box-sizing: border-box;
    width: 100vw;
    padding: rem(10) $offset-sm;
    font-size: rem(15);
  }

  .d2 {
    &__item {
      display: block;
      padding: rem(5) 0;
    }
  }
}

.footer-links {
  display: flex;
  flex-wrap: wrap;

  & > li {
    position: relative;
    margin-right: rem(13);

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      right: 0;
      width: 1px;
      height: 14px;
      background-color: $gray3;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }
  }

  &--bottom {
    li {
      margin-top: rem(13);
    }
  }
}

.footer-link {
  padding-right: rem(13);
  font-size: rem(15);
  color: $gray6;

  b {
    color: $gray9;
  }

  &__icon {
    margin-left: 5px;

    @include icon('new-window') {
      font-size: rem(15);
      color: $gray9;
      vertical-align: bottom;
    }
  }
}

.footer-sns {
  display: flex;

  &__item {
    &:first-child {
      .icon {
        padding-left: 0;
        padding-right: rem(15);
      }
    }
  }

  &__link {
    display: block;
  }

  .icon {
    font-size: rem(24);
    padding: rem(10) rem(10);
    color: $gray9;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}

.footer-family {
  .footer-toggle {
    &__cont {
      @include clearfix;
    }

    &__link {
      float: left;
      width: 50%;
    }
  }
}

@include mq($until: large) {
  .footer {
    .linkline {
      &:hover,
      &:focus {
        .linkline__text {
          background-size: 0% 100%;
        }
      }
    }
  }

  .footer-logo {
    &__svg {
      width: $logo-w-sm;
    }
  }

  .footer-nav {
    font-family: $font-sub-kor;
    margin-top: rem(32);

    [lang='en'] & {
      font-family: $font-main-eng;
    }

    .d1 {
      &__item {
        position: relative;
        display: block;
        padding: rem(18.5) 0;
        font-size: rem(17);
        font-weight: 500;
        overflow: hidden;

        @include icon('arrow-down-bold') {
          position: absolute;
          top: 50%;
          right: -2px;
          transform: translateY(-50%);
          transition: transform $dur $ease;
        }
      }

      &.is-active {
        .d1 {
          &__item {
            &::before {
              transform: translateY(-50%) rotate(-180deg);
            }
          }
        }
      }
    }
  }

  .footer-link {
    color: $gray6;
    font-size: rem(15);
  }

  .footer-info {
    margin-top: rem(20);
  }

  .footer-sns {
    margin-bottom: rem(10);
  }

  .footer-toggle {
    position: relative;
    margin-top: rem(35);

    &__item {
      @include full-deco('border', rgba($gray9, 0.2));

      &.is-active {
        .footer-toggle__btn {
          &::before {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
    }

    &__btn {
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      padding: rem(18) 0;
      color: $gray9;
      font-size: rem(15);
      text-align: left;
      overflow: hidden;

      @include icon('arrow-down-bold') {
        position: absolute;
        top: 50%;
        right: -2px;
        transform: translateY(-50%);
        transition: transform $dur $ease;
      }
    }

    &__pad {
      @include full-deco('bg', $gray1);
    }

    &__cont {
      position: relative;
      margin: 0;
      padding: rem(10) 0;
      display: none;
      // overflow-x: visible !important;

      address {
        margin: rem(5) 0;
      }
    }

    &__link {
      display: block;
      padding: rem(5) 0;
    }
  }
}

@include mq(medium) {
  .footer-family {
    .footer-toggle {
      &__link {
        width: auto;
        margin-right: rem(20);
      }
    }
  }
}

@include mq(large) {
  .footer {
    padding-top: rem(55);
    padding-bottom: rem(65);

    &__pad {
      padding-bottom: rem(85);
    }
  }

  .footer-logo {
    position: absolute;
    top: 5px;
    left: 0;

    &__svg {
      width: $logo-w-lg;
    }
  }

  .footer-nav {
    .d1-list {
      display: flex;
    }

    .d1 {
      &--about {
        width: 15%;
        max-width: 130px;
      }
      &--product {
        width: 30%;
        max-width: 153px;
      }
      &--innovation {
        width: 25%;
        max-width: 146px;
      }
      &--story {
        width: 15%;
        max-width: 113px;
      }
      &--carrer {
        width: 15%;
        max-width: 110px;
      }

      &__item {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .sub-wrap {
      &::after {
        display: none;
      }
    }

    .d2-list {
      position: static;
      margin-top: rem(22);
      width: auto;
      padding: 0;
    }

    .d2 {
      &__item {
        display: block;
        padding: rem(5) 0;
        color: $gray7;
      }
    }
  }

  .footer-info {
    order: -1;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: rem(52);
    line-height: 1.5;
  }

  .footer-links {
    &--bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;

      & > li {
        margin-bottom: rem(5);
      }
    }
  }

  .footer-family {
    position: absolute;
    right: 0;
    bottom: 0;

    .footer-toggle__btn {
      position: relative;
      display: block;
      width: 210px;
      padding: rem(8) 0;
      border-bottom: 1px solid rgba($gray9, 0.4);
      text-align: left;
      cursor: pointer;

      @include icon('arrow-up-bold') {
        position: absolute;
        top: 10px;
        right: 0;
        transition: transform $dur $ease;
      }
    }

    .footer-toggle__cont {
      display: none;
    }

    &.is-active {
      .footer-toggle {
        &__cont {
          display: block;
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          padding: rem(15) 0;
          border: 1px solid rgba($gray9, 0.2);
          background-color: $gray0;
          transform: translateY(-100%);
        }

        &__link {
          display: block;
          padding: rem(1) rem(20);
        }

        &__btn {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .footer-address {
    display: flex;
    flex-direction: column-reverse;
  }

  .footer-copyright {
    margin: rem(5) 0 rem(30);
  }

  .footer-sns {
    margin-top: rem(10);
    order: -1;

    &__item {
      &:first-child {
        .icon {
          padding-right: rem(10);
        }
      }
    }
  }
}
