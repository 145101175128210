// -----------------------------------------------------------------------------
// ll-header
// -----------------------------------------------------------------------------

.ll-header {
  &__hero {
    position: relative;
    margin-top: $header-height-sm;
    height: calc(100vh - #{$header-height-sm});

    .has-lnb & {
      margin-top: $header-height-sm + $lnb-height-sm;
      height: calc(100vh - #{$header-height-sm + $lnb-height-sm});

      .mobile & {
        height: calc(
          var(--vh100, 100vh) - #{$header-height-sm + $lnb-height-sm}
        );
      }
    }

    .mobile & {
      height: calc(var(--vh100, 100vh) - #{$header-height-sm});
    }
  }

  &__bg {
    @include full-pos;
    overflow: hidden;
  }

  &__cont {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 0 $offset-sm;
  }

  &__h {
    // margin-bottom: rem(5);
    transform-origin: 0% 0%;
    font-family: $font-sub-kor;
    font-size: rem(46);
    line-height: 1.2;

    [lang='en'] & {
      font-family: $font-main-eng;
    }
  }

  &__sub {
    font-size: rem(24);
    line-height: 1.3;
    @include break-word;
  }

  &__func {
    margin-top: rem(25);
  }

  .follow {
    padding: rem(80) 0;

    &__h {
      display: block;
      font-size: rem(24);
      line-height: 1.45;
      @include break-word;
    }

    &__desc {
      margin-top: rem(15);
    }
  }

  &--invert {
    .ll-header {
      &__h,
      &__sub {
        color: $white;
      }

      &__bg {
        &::after {
          content: '';
          @include full-pos;
          background-color: rgba($gray9, 0.1);
        }
      }
    }
  }

  &--banner {
    .ll-header {
      &__hero {
        margin-top: $header-height-sm;
        height: calc(100vh - #{$header-height-sm});

        .has-lnb & {
          margin-top: $header-height-sm + $lnb-height-sm;
          height: calc(100vh - #{$header-height-sm + $lnb-height-sm});

          .mobile & {
            height: calc(
              var(--vh100, 100vh) - #{$header-height-sm + $lnb-height-sm}
            );
          }
        }
      }

      &__h {
        font-size: rem(36);
      }

      &__desc {
        display: block;
        margin-top: rem(10);
      }

      &__box {
        margin-bottom: 0;
      }

      &__func {
        margin-top: rem(25);
      }
    }

    .follow {
      padding: rem(50) 0;

      &__h {
        font-size: rem(28);
      }
    }
  }

  @include mq(medium) {
    &__h {
      font-size: rem(88);
    }

    &__sub {
      display: block;
      margin-top: rem(5);
      font-size: rem(28);
    }

    &__cont {
      padding-left: $offset-md;
      padding-right: $offset-md;
    }

    .follow {
      &__h {
        font-size: rem(28);
      }
    }

    &--banner {
      .ll-header {
        &__h {
          font-size: rem(68);
        }
      }
    }
  }

  @include mq(large) {
    &__hero {
      margin-top: $header-height-lg;

      .has-lnb & {
        margin-top: $header-height-lg + $lnb-height-lg;
      }
    }

    &__box {
      padding-bottom: rem(45);
    }

    &__h {
      margin-bottom: 0;
    }

    &__func {
      margin-top: rem(35);
    }

    .follow {
      padding: rem(110) 0 rem(200);

      &__h {
        position: absolute;
        top: 0px;
        left: 0;
        width: 51%;
        max-width: 450px;
      }

      &__desc {
        margin: 0;
        padding-left: 51%;
        font-size: rem(20);
      }
    }

    &--banner {
      .ll-header {
        &__hero {
          min-height: 400px;
          height: 70vh;
          max-height: 600px;
          margin-top: $header-height-lg;

          .has-lnb & {
            margin-top: $header-height-lg + $lnb-height-lg;
          }
        }

        &__box {
          padding-bottom: rem(0);
        }

        &__func {
          margin-top: rem(35);
        }
      }

      .follow {
        padding: rem(70) 0 rem(180);
      }
    }
  }

  @include mq(x-large) {
    &__cont {
      width: 100%;
      padding: 0;
    }

    &__box {
      max-width: $w-default;
      margin: 0 auto;
    }
  }
}
