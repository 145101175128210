// -----------------------------------------------------------------------------
// Product List
// -----------------------------------------------------------------------------

.product-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(15);
  margin-bottom: rem(35);
}

.product {
  width: calc(50% - 5px);
  margin-top: rem(20);

  &:nth-child(even) {
    margin-left: rem(10);
  }

  &__link {
    display: block;
  }

  &__thumb {
    display: block;
    padding: rem(5) 4%;
    background-color: $gray0;
  }

  &__h {
    margin-top: rem(5);
    font-weight: 500;
  }

  &__desc {
    display: block;
    color: $gray4;
  }

  @include mq(medium) {
    text-align: center;
    width: calc(50% - 10px);

    &:nth-child(even) {
      margin-left: rem(20);
    }

    &__link {
      background-color: $gray0;
      padding: rem(25) 0 rem(50);
    }

    &__thumb {
      padding: rem(30) 24%;
      background-color: $gray0;
    }

    &__h {
      margin: 0 0 rem(5);
    }
  }
}
