// -----------------------------------------------------------------------------
// Mixin
// -----------------------------------------------------------------------------

@mixin icon-styles {
  display: inline-block;
  font-family: 'icon';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

@mixin icon($filename, $position: before, $styles: true) {
  @if $position == both {
    $position: 'before, &:after';
  }

  // Either a :before or :after pseudo-element, or both, defaulting to :before
  &:#{$position} {
    @if $filename {
      // A particular icon has been specified
      @if map-has-key($icon-char, $filename) {
        $icon-unicode: map-get($icon-char, $filename);
        content: unicode(#{$icon-unicode});
      } @else {
        @content;
        @warn "\"#{$filename}.svg\" file `does not exist in the mapping";
      }
    }
    @if $styles {
      @include icon-styles;
    }
    // Include any extra rules supplied for the pseudo-element
    @content;
  }
}

// font-face 선언 만들기
@mixin font-face($family, $filename, $weight: 400) {
  //noinspection CssUnknownTarget
  @font-face {
    font-family: '#{$family}';
    font-style: normal;
    font-weight: $weight;
    src: url(../font/#{$filename}.woff2) format('woff2'),
         url(../font/#{$filename}.woff) format('woff');
  }
}

@mixin animation-underline($color, $height) {
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - #{$height}), $color #{$height});
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.3s;
}

@mixin clearfix {
  zoom: 1;

  &:before {
    content: '';
    display: block;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin full-pos {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin break-word {
  word-break: keep-all;
  word-wrap: break-word;
}

@mixin ellipsis($lines: false) {
  @if ($lines) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-wrap: normal;
  } @else {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
  }
}

@mixin v-mid($child: '&-cell') {
  white-space: nowrap;

  #{$child} {
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    @content;
  }

  &:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

@mixin flex-mid() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}

@mixin bar-list(
  $margin,
  $inline-space: 1px,
  $bar-height: 10px,
  $bar-width: 1px,
  $bar-color: $silver-light
) {
  position: relative;
  display: inline-block;
  margin-left: $margin - $inline-space;
  padding-left: $margin + $bar-width;

  &:before {
    content: '';
    position: absolute;
    height: $bar-height;
    left: 0;
    top: 50%;
    margin-top: $bar-height / -2;
    border-left: $bar-width solid $bar-color;
  }

  &:first-child {
    margin-left: 0;
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}

@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: transparent;
  height: 0;
  width: 0;

  @if ($direction == up) or
    ($direction == down) or
    ($direction == right) or
    ($direction == left)
  {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  }
}

@mixin gradient($direction, $color-stops...) {
  /*@if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }*/

  background: nth(nth($color-stops, 1), 1);
  background: -moz-linear-gradient(legacy-direction($direction), $color-stops);
  background: -webkit-linear-gradient(
    legacy-direction($direction),
    $color-stops
  );
  background: linear-gradient(unquote($direction), $color-stops);

  $start-color: nth($color-stops, 1);
  $end-color: nth($color-stops, 2);

  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 ); // IE6-9 fallback on horizontal gradient
}

// Mixin to customize scrollbars
// @include scrollbars(selector, .5em, foreground, background);
@mixin scrollbars($selector, $size, $foreground-color, $background-color) {
  // For -webkit-
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For -ms-
  @if $selector == null {
    body {
      scrollbar-face-color: $foreground-color;
      scrollbar-arrow-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  } @else {
    #{$selector} {
      scrollbar-face-color: $foreground-color;
      scrollbar-arrow-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
  }
}

@mixin objectfit-polyfill() {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  min-width: 100%;
  min-height: 100%;
  max-width: none !important;
  width: auto !important;
  height: auto !important;
  transform: translate3d(-50%, -50%, 0) !important;
}

@mixin full-deco($type, $color, $position: after) {
  position: relative;

  &::#{$position} {
    content: '';
    position: absolute;
    width: 100vw;
    top: 0;
    left: -$offset-sm;

    @if $type == bg {
      background-color: $color;
      height: 100%;
      z-index: -1;
    }

    @if $type == border {
      border-top: 1px solid $color;
    }

    @content;
  }

  @include mq(medium) {
    &::before,
    &::after {
      left: -$offset-md;
    }
  }

  @include mq(x-large) {
    &::before,
    &::after {
      left: -$offset-lg;
    }
  }
}
