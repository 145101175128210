.ll-sect {
  &__bg {
    overflow: hidden;
    position: relative;
    height: 480px;

    &.has-sub {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30%;
        @include gradient('0deg', rgba($white, 1), rgba($white, 0));
      }
    }
  }

  &__cont {
    padding: rem(50) 0 rem(100);
  }

  &__h {
    margin-bottom: rem(15);
  }

  &__desc {
    margin: 0;
  }

  &__func {
    margin-top: rem(25);
  }

  &__lft_title {
    font-size: 3.2rem;
    font-weight: 800;
    font-family: "Roboto", sans-serif;
    margin-bottom: 1.8rem;
  }

  @media (min-width: 45em) {
    /* ******  i pad + PC 적용 ****** */
    &__lft_title {
      font-size: 4.2rem;
      font-weight: 800;
      line-height: 3.5rem;
      margin-bottom: 1.8rem;
    }
  }

  @include mq(large) {
    &__bg {
      overflow: hidden;
      position: relative;
      height: 720px;
    }

    &__cont {
      padding: rem(90) 0 rem(140);
    }

    &__box {
      width: 49%;
      margin-left: auto;
    }

    &__func {
      margin-top: rem(35);
    }
  }
}
