@charset "UTF-8";
// -----------------------------------------------------------------------------
// Style
// -----------------------------------------------------------------------------

// utility
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/util/variable';
@import '../../../../../support/front/scss/util/icon-char'; // task
@import '../../../../../support/front/scss/util/function';
@import '../../../../../support/front/scss/util/mixin';

// vendor style (npm dependencies)
// -----------------------------------------------------------------------------
@import 'sass-mq/_mq.scss';

// font
@each $filename, $props in $font-list-eng {
  @include font-face(nth($props, 1), $filename, nth($props, 2));
}

// base
@import '../../../../../support/front/scss/base/reset';
@import '../../../../../support/front/scss/base/helper';

// module
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/module/icon'; // task
@import '../../../../../support/front/scss/module/common';
@import '../../../../../support/front/scss/module/grid';
@import '../../../../../support/front/scss/module/typography-eng';
@import '../../../../../support/front/scss/module/button';
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_dropdown-list.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_dropdown.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_flex-group.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form-agree.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form-file.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form-group.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form-radiocheck.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form-row.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_form.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_img-upload.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_input-group.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_modal.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_range-bar.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/form/_textarea.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_accordion.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_back-to-top.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_badge.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_category.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_indicator.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_landscape-veil.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_layerpopup.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_link.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_list.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_ll-header.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_ll-section.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_lnb.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_no-result.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_notibox.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_pagination.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_product-list.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_search.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_shelf.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_tab.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_table.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_tag.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/module/component/_video-box.scss";

// vendor
// -----------------------------------------------------------------------------
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/vendor/_flatpickr.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/vendor/_notyf.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/vendor/_nouislider.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/vendor/_swiper.scss";
@import "E:/side-project/LocknLock/locknlock-site/support/front/scss/vendor/_tingle.scss";

// layout
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/layout/component';
@import '../../../../../support/front/scss/layout/header';
@import '../../../../../support/front/scss/layout/footer';
@import '../../../../../support/front/scss/layout/wrapper';
@import '../../../../../support/front/scss/layout/nav';

// add new style css
// -----------------------------------------------------------------------------
@import '../../../../../support/front/scss/style__add_2022';

// ENG
// -----------------------------------------------------------------------------

// cookie accept
.cookie {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: $header-zindex + 2000;
  width: 100%;
  padding: 30px 0 40px;

  background-color: $gray7;
  color: $white;
  font-size: rem(16);
  font-weight: 300;
  line-height: 1.75;

  p {
    padding-right: 15%;
    margin-bottom: 1.25em;
  }
  a {
    display: inline-block;
    font-weight: 500;
    color: inherit;
  }

  @include mq($until: medium) {
    &__pad {
      padding-left: 10px;
      padding-right: 10px;
    }

    p {
      padding-right: 0;
    }
  }
}

.footer {
  @include mq(large) {
    &__pad {
      padding-bottom: rem(115);
    }
    &-copyright {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
    }

    &-sns {
      margin-top: rem(20);
    }
  }
}
