// -----------------------------------------------------------------------------
// Tab
// -----------------------------------------------------------------------------

.tab {
  &-pad {
    position: relative;
    display: flex;
    border: 0 solid $gray3;
    border-width: 0 0 1px 1px;
    border-bottom-color: $gray4;
    justify-content: space-between;
  }

  &-a {
    display: block;
    box-sizing: border-box;
    flex: 1;
    padding: 14px 15px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    border: 0 solid $gray3;
    border-width: 1px 1px 0 0;
    background-color: $gray0;
    cursor: pointer;

    &.is-current {
      position: relative;
      z-index: 1;
      color: $color-primary;
      border-top: 2px solid $color-primary;
      border-bottom-color: $gray4;
      background-color: $white;

      &::before {
        content: '';
        position: absolute;
        z-index: 10;
        left: -1px;
        top: -1px;
        bottom: 0;
        display: block;
        border-left: 1px solid $gray5;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: -1px;
        display: block;
        border-bottom: 1px solid $white;
      }
    }
  }

  &-cont {
    display: none;

    .is-active & {
      display: block;
    }
  }

  &-panel {
    display: none;

    &.is-active {
      display: block;
    }
  }

  // Type Variation
  // ---------------------------------------------------------------------------
  &--inline {
    .tab-pad {
      justify-content: flex-start;
    }

    .tab-a {
      flex: none;
    }
  }
  &--inlinec {
    .tab-pad {
      justify-content: center;
    }

    .tab-a {
      flex: none;
    }
  }

  &--scrollable {
    @include mq($until: medium) {
      overflow-x: auto;

      .tab-pad {
        width: 800px;
      }
    }
  }

  &--fix {
    $tab-a-height: 56px;
    position: relative;
    padding-top: $tab-a-height + 20px;
    box-sizing: border-box;

    .tab-a {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      border-bottom: 1px solid $gray5;
      height: $tab-a-height;
    }

    .tab-item {
      &:first-child {
        .tab-a {
          left: 0;
          border-left: 1px solid $gray3;
        }
      }
    }

    @for $i from 1 through 6 {
      &.tab--#{$i} {
        $width: 100% / #{$i};

        .tab-a {
          width: $width;
        }
      }
    }

    &.tab--3 {
      .tab-item {
        &:nth-child(2) {
          .tab-a {
            left: percentage(1/3);
          }
        }

        &:nth-child(3) {
          .tab-a {
            left: percentage(2/3);
          }
        }
      }
    }

    &.tab--4 {
      .tab-item {
        &:nth-child(2) {
          .tab-a {
            left: percentage(1/4);
          }
        }

        &:nth-child(3) {
          .tab-a {
            left: percentage(2/4);
          }
        }

        &:nth-child(4) {
          .tab-a {
            left: percentage(3/4);
          }
        }
      }
    }
  }

  // Style Variation
  // ---------------------------------------------------------------------------
  &--line {
    .tab-pad {
      border-left: 0;
    }

    .tab-a {
      position: relative;
      background: none;
      border: 0;
      margin: 0 2px;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 0%;
        height: 3px;
        background-color: $color-primary;
        transition: all 0.2s ease-out;
      }

      &.is-current {
        color: $dark;

        &:after {
          bottom: 0;
          width: percentage(1);
          border: 0;
          background-color: $dark;
        }
      }

      &:focus,
      &:hover {
        color: $color-primary;

        &:after {
          width: percentage(1);
          background-color: $color-primary;
        }
      }
    }
  }

  &--linetriangle {
    position: relative;

    .tab-pad {
      border-left: 0;
    }

    .tab-a {
      position: relative;
      background: none;
      border: 0;

      &:focus,
      &:hover {
        color: $color-primary;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border: solid transparent;
        pointer-events: none;
      }

      &.is-current {
        color: $dark;

        &:before {
          margin-left: -8px;
          border-width: 8px;
          border-top-color: $gray4;
        }

        &:after {
          margin-left: -7px;
          border-width: 7px;
          border-top-color: $white;
        }
      }
    }
  }
}