// -----------------------------------------------------------------------------
// Category
// -----------------------------------------------------------------------------

.category {
  display: inline-block;
  padding: 6.5px 19.5px;
  border: 1px solid $gray8;
  color: $gray9;
  font-size: 1.6rem;
  border-radius: 5px;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out, color 0.2s ease-out;
  margin: 5px;
  margin-left: 0;

  &.is-selected,
  &:hover,
  &:focus {
    color: $white;
    border-color: $gray9;
    background-color: $gray9;
  }
}
