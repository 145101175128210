// -----------------------------------------------------------------------------
// Form > File
// -----------------------------------------------------------------------------

.input-file {
  position: relative;

  input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
  }
  &-inner {
    position: relative;
    display: inline-block;
  }
  &-attached {
    display: inline-block;
    padding-top: 5px;
    margin-left: 10px;
    vertical-align: middle;
  }

  .btn {
    height: auto !important;
  }

  .file-name {
    margin-right: 10px;
  }
}
