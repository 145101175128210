// -----------------------------------------------------------------------------
// Form
// -----------------------------------------------------------------------------

// form element reset style
// -----------------------------------------------------------------------------
form {
  margin: 0;
}

fieldset {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  // min-inline-size: min-content;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

button,
select {
  text-transform: none;
}

// progress {
//   display: inline-block;
//   vertical-align: baseline;
// }

// this style for IE10-
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  vertical-align: middle;
}

// number type 컨트롤에서 spinner 감춤
[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    height: auto;
    -webkit-appearance: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

// search type 컨트롤에서 관련 버튼 감춤
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
    // -webkit-appearance: none;
  }
}

// date type 컨트롤에서 관련 버튼 감춤
input[type='date'],
input[type='datetime-local'] {
  &::-webkit-inner-spin-button {
    display: none;
    margin: 0;
  }

  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &::-webkit-datetime-edit-fields-wrapper {
    padding: 0;
  }
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[type='text'],
[type='number'],
[type='tel'],
[type='search'],
[type='password'],
[type='email'],
[type='url'],
[type='date'],
[type='datetime-local'],
[type='file'],
select,
textarea {
  box-sizing: border-box;
  padding: $input-padding-tb $input-padding-lr;
  border: 1px solid $silver-light;

  background: $white;
  color: $ink;
  font-size: 15px;
  line-height: 1.25;
  outline: none;
  vertical-align: middle;

  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: border-color 0.3s;

  &:hover,
  &:focus {
    border-color: $gray9;
  }

  &[readonly],
  &[disabled] {
    color: $silver-dark;
    background: $fog;
    opacity: 1;
    pointer-events: none;

    &:hover,
    &:focus {
      border-color: $silver-light;
    }
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

label {
  cursor: pointer;
}

select {
  display: inline-block;
  padding-right: 40px;

  &::-ms-expand {
    display: none;
  }

  .csstransforms3d & {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAmElEQVQ4je3QvQnCUBSG4SeQIivYWlgJamflCE5g5ww6hKW1VjqA7uAsgo0giE3A2JxAwERJrS9cOHw/514uf6oMcET2IZNFZlhnnlDggLTGT8MrIvtGF+cI7JBUvASb8C7oNT2xj2sE1xV9FdoNo6ZyyRj3KCywjPmBybdyyRQ5nnHy0FoxqyyYN4Xqfrtkj07M27a3/xIvorEfXLVHtUEAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) 53%;
    background-size: 16px auto;

    &[multiple] {
      background: transparent;
    }
  }
}

textarea {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  min-height: 166px;
  padding-top: 8px;

  line-height: 1.6;
  vertical-align: top;
  resize: vertical;
  @include break-word;
}

// input/select size variation
// -----------------------------------------------------------------------------
.input {
  &-block {
    display: block;
    width: 100%;

    &:not(textarea) {
      max-height: 50px;
    }
  }
}

// placeholder 색상
// -----------------------------------------------------------------------------
$placeholder-color: $gray6;

::-webkit-input-placeholder {
  color: $placeholder-color;
}
::-moz-placeholder {
  color: $placeholder-color;
}
:-ms-input-placeholder {
  color: $placeholder-color;
}
:-moz-placeholder {
  color: $placeholder-color;
}

input[readonly] {
  &::-webkit-input-placeholder {
    color: $gray4;
  }
  &::-moz-placeholder {
    color: $gray4;
  }
  &:-ms-input-placeholder {
    color: $gray4;
  }
  &:-moz-placeholder {
    color: $gray4;
  }
}

select {
  &:not(:focus):invalid {
    color: $placeholder-color;
  }
}

// common style
// -----------------------------------------------------------------------------
.form {
  .legend {
    display: block;
    width: 100%;
    padding: 0 0 rem(12);
    border-bottom: 2px solid $gray9;

    &.is-required {
      .h6 {
        &::after {
          content: '*';
          display: inline-block;
          margin-left: rem(5);
        }
      }
    }

    & + .desc {
      margin-top: 0;
    }
  }

  .label {
    display: block;
    margin-bottom: rem(10);
  }

  .agree-all {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__func {
    margin-top: rem(20);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .desc {
    display: block;
    margin-top: rem(12);
    color: $gray6;
    font-size: rem(16);
    line-height: 1.6;
  }

  .uploaded-file {
    margin-top: rem(3);

    .file {
      position: relative;
      display: inline-block;
      color: $gray6;
      font-size: rem(15);
      padding-right: 32px;

      &__delete {
        position: absolute;
        top: -4px;
        right: 0;
        width: 20px;
        height: 20px;
        padding: rem(7);
        display: flex;
        align-items: center;
        justify-content: center;

        @include icon('close') {
          color: $gray9;
          font-size: rem(20);
        }
      }
    }
  }

  .messages {
    .error {
      display: block;
      font-size: 13px;
      margin-top: 7px;
      color: $color-error;

      @include icon('error', before) {
        margin-right: 5px;
        vertical-align: middle;
        font-size: 15px;
        color: $color-error;
      }
    }
  }

  @include mq(large) {
    .legend {
      & + .desc {
        margin-bottom: rem(25);
      }
    }
    &__func {
      margin-top: rem(40);
    }
  }
}
