// -----------------------------------------------------------------------------
// link
// -----------------------------------------------------------------------------

.link {
  color: $gray9;
  font-size: rem(17);
  border-bottom: 1px solid $gray9;
  transition: all 0.2s $ease;

  &::before,
  &::after {
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $gray9;
  }

  .no-touchevents & {
    &:hover,
    &:focus {
      color: $color-secondary;
      border-bottom-color: $color-secondary;
    }
  }

  &--ghost {
    color: $white;
    border-bottom: 1px solid $white;
  }
}

.linkline {
  line-height: 1.4;

  .no-touchevents & {
    &:hover,
    &:focus {
      color: $color-primary;

      .linkline__text {
        background-size: 100% 100%;
      }
    }
  }

  &__text {
    @include animation-underline($color-primary, 1px);
  }

  &--ghost {
    color: $white;
    .no-touchevents & {
      &:hover,
      &:focus {
        color: $white;

        .linkline__text {
          background-size: 100% 100%;
        }
      }
    }

    .linkline {
      &__text {
        @include animation-underline($white, 1px);
      }
    }
  }
}

.linkblock {
  display: block;

  &__box {
    overflow: hidden;
  }

  &__img {
    transition: $dur ease-out;
  }

  .linkline__text {
    @include animation-underline($color-primary, 2px);
  }

  .no-touchevents & {
    &:hover,
    &:focus {

      .linkblock__img {
        transform: scale(1.1);

      }

      .linkline__text {
        background-size: 100% 100%;
        transition: $dur $ease;
      }
    }
  }

  .no-objectfit & {
    &:hover,
    &:focus {
      .linkblock__img {
        &.bg__img {
          transform: translate3d(-50%, -50%, 0) scale(1.1) !important;
        }
      }
    }
  }

  &--bg {
    @include full-pos;

    .linkline__text {
      color: $white;
      @include animation-underline($white, 2px);
    }
  }
}
