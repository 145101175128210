// -----------------------------------------------------------------------------
// Element Style Reset
// -----------------------------------------------------------------------------

html {
  background-color: #fff;
  font-size: 62.5%;   // 10px
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

body {
  min-width: 280px;
  margin: 0;
  font-size: 1.8em;
  font-family: $font-main-kor;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  [lang='en'] & {
    font-family: $font-main-eng;
  }
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

ul,
ol,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

em,
address {
  font-style: normal;
}

figure {
  margin: 0;
}

hr {
  box-sizing: content-box;
  overflow: visible;
  height: 0;
}

button {
  -webkit-appearance: none;
  box-sizing: content-box;
  padding: 0;
  border: 0;
  background: transparent;
  color: $gray9;
  cursor: pointer;
}

table {
  border-collapse: collapse;
	border-spacing: 0;
  empty-cells: show;
}
td {
  vertical-align: middle;
}

em {
  color: $color-secondary-dark;
}

small {
  font-size: 80%;
}

// Add the correct display in IE 9-.
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  // color: $ink;
  // font-weight: normal;

  // 국문일 경우 여기서 break-word 값을 지정해주는 것이 좋다.
  @include break-word;
}

// 국문일 경우 여기서 break-word 값을 지정해주는 것이 좋다.
// word-break: keep-all;
// word-wrap: break-word;
p {
  @include break-word;
}

a {
  color: $gray9;
  text-decoration: none;
  background-color: transparent;

  // Remove gaps in links underline in iOS 8+ and Safari 8+.
  -webkit-text-decoration-skip: objects;
}

iframe {
  border: 0;
}

img,
video {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: top;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

summary {
  display: list-item;
}

::selection {
  background: $color-secondary;
  color: $white;
  text-shadow: none;
}


// 자주 안 쓰는 요소
// -----------------------------------------------------------------------------
// 사용할 경우 주석 해제

// abbr[title] {
//   // Remove the bottom border in Chrome 57- and Firefox 39-.
//   border-bottom: none;

//   text-decoration: underline; /* 2 */
//   text-decoration: underline dotted; /* 2 */
// }

// code,
// kbd,
// samp {
//   font-family: monospace, monospace;
//   font-size: 1em;
// }

// mark {
//   background-color: #ff0;
//   color: #000;
// }

// pre {
//   white-space: pre-wrap;
//   word-wrap: break-word;
// }

// sup {
//   vertical-align:text-top;
// }
// sub {
//   vertical-align:text-bottom;
// }
// sub,
// sup {
//   font-size: 75%;
//   line-height: 0;
//   position: relative;
//   vertical-align: baseline;
// }
// sub { bottom: -0.25em; }
// sup { top: -0.5em; }
