/* ******  왼쪽 타이틀 적용 ****** */
.ll-sect__lft_title {
  font-size: 3.2rem;
  line-height: 1.8em;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1.8rem;
  margin-right: 3%;
}

@media (min-width: 45em) {
  /* ******  i pad + PC 적용 ****** */
  .ll-sect__lft_title {
    font-size: 4.2rem;
    line-height: 1.8em;
    font-weight: 800;
    line-height: 3.5rem;
    margin-bottom: 1.8rem;
    margin-right: 3%;
  }
}

/* ******  메인페이지 슬라이드 화살표 적용 ******   2022-10-04 수정 */

.swiper_lt {
  width: 45px;
  height: 60px;
  background: rgba(200, 0, 0, 0);
  position: absolute;
  left: 8px;
  top: 48vh;
  transition: all ease 1s 0s;
}

.swiper_rt {
  width: 45px;
  height: 60px;
  background: rgba(200, 0, 0, 0);
  position: absolute;
  right: 8px;
  top: 48vh;
  transition: all ease 1s 0s;
}

.swiper_lt:hover {
  background: rgba(0, 0, 0, 0.2);
}

.swiper_rt:hover {
  background: rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) { /* ******  i pad  ****** */
  .swiper_lt {
    left: 30px;
  }
  .swiper_rt {
    right: 30px;
  }
}

@media (min-width: 992px) { /* ******   PC  ****** */
  .swiper_lt {
    left: 44px;
    width: 60px;
    height: 80px;
  }
  .swiper_rt {
    right: 44px;
    width: 60px;
    height: 80px;
  }
}

/* ****** END 메인페이지 슬라이드 화살표 적용 END ******   2022-10-04 수정 */

/* ******  wing banner jylee ****** 메인 index.html 옮긴것  2022-10-04 수정 */

.contents-area {
  width: 500px;
  height: 600px;
  border: 1px solid black;
  text-align: center;
}

/* ******  @media ESG, about 메인화면 컴포넌트 2단 적용  ****** */
.two_col__box {
  display: block;
}

@media (min-width: 60em) {
  /* ******  PC 만 적용 ****** */
  .two_col__box {
    display: flex;
  }
}

/* //////// END  @media ESG, about 메인화면 컴포넌트 2단 적용 END //////// */


/* p2 풋터 메뉴부분 레이아웃 간격체크 */
@media (min-width: 60em) {
  .footer-nav .d1--esg {
    width: 15%;
    max-width: 115px;
  }
  .gnb .d1--esg .d2-list {
    -webkit-transform: translateX(150px);
    -ms-transform: translateX(150px);
    transform: translateX(150px);
  }
}


/* ------ 플로팅 배너 2022-10-25 ----- */
/* ------ 플로팅 배너 2022-10-25 ----- */

.wing-area {
  position: fixed;
  z-index: 99;
  bottom: 60px;
  width: 270px;
  right: 20px;
}

.wing_top {
  display: block;
  margin-bottom: 60px;
}

/*  mobile  */
@media (min-width: 768px) {
  .wing-area {
    width: 270px;
    right: 45px;
  }
}

/*  i pad  */
@media (min-width: 992px) {
  .wing-area {
    width: 280px;
    right: 60px;
  }
}

.wing a {
  display: block;
}

.ft_banner {
  position: absolute;
  right: 1px;
  bottom: -2px;

  .wrapper {
    position: relative;
  }

  ul {
    width: 80px;
    height: 80px;
    bottom: 0;
    right: 0;
    background: #3C3AF5;
    border-radius: 40px;
    padding: 23px 0 0;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: height 0.45s ease;
    transition: height 0.45s ease;
    box-shadow: 1px 5px 16px rgba(0, 0, 0, 0.35);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    text-decoration: none;

    li {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0;
      list-style: none;
      text-decoration: none;
      margin: 0 0 10px;

      a {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;
      }
    }


    > li {
      visibility: hidden;
      opacity: 0;
      text-align: center;
      margin-bottom: 10px;
      -webkit-transition: visibility .7s ease, opacity .7s ease;
      transition: visibility 0.7s ease, opacity 0.7s ease;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        display: inline-block;
        -webkit-transition: opacity .45s ease;
        transition: opacity 0.45s ease;

        &:hover {
          opacity: 0.65;
        }

        > span {
          display: inline-block;
          width: 100%;
          height: 20px;
          font-size: 12px;
          font-weight: 600;
          color: #fff;
          overflow: hidden;
          opacity: 0;
          -webkit-transition: opacity 0.3s ease;
          transition: opacity 0.3s ease;
        }
      }

      &.ft_button {
        visibility: visible;
        opacity: 1;
      }
    }

    &.hover {
      height: 254px;
      cursor: default;

      > li {
        visibility: visible;
        opacity: 1;

        > a > span {
          opacity: 1;
        }
      }
    }
  }

  .ft_icon_1 {
    width: 38px;
    padding-bottom: 7px;
    padding-top: 1px;
  }

  .ft_icon_2 {
    width: 35px;
    padding-bottom: 10px;
  }

  .ft_icon_3 {
    width: 32px;
    padding-bottom: 7px;
  }

  .ft_icon_pdr {
    padding-right: 5px;
  }

  .ft_desc {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
  }
}

@media (max-width: 1600px) {
  .ft_banner .ft_icon {
    width: 2.2rem;
  }
}

@media (max-width: 970px) {
  .ft_banner {
    ul {
      width: 70px;
      height: 70px;
      padding-top: 18px;

      > li {
        margin-bottom: 18px;
      }

      &.hover {
        height: 186px;
      }
    }

    .ft_icon_1 {
      width: 36px;
      padding-bottom: 5px;
    }

    .ft_icon_2 {
      width: 40px;
      padding-bottom: 5px;
    }

    .ft_icon_3 {
      width: 36px;
      padding-bottom: 5px;
    }
  }

  .ft_dec {
    display: none !important;
  }

  .msg_box-container {
    display: none;
  }


}

@media (max-width: 480px) {
  .ft_banner {
    bottom: -38px;
  }
}

/* ------ 플로팅 배너 2022-10-30 ----- */
.msg_box-container {
  position: fixed;
  width: 360px;
  height: 100px;
  right: 157px;
  bottom: 50px;
  padding: 0;
  z-index: 1998;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .msg_box {
    width: 354px;
    height: 102px;
    padding: 2px 2px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 5px 8px 20px rgba(0, 0, 0, 0.12);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    .msg_title {
      width: 300px;
      font-size: 1.5rem;
      color: #111;
      line-height: 1.35em;
      font-weight: 700;
      text-align: left;
      margin: 9px 0 8px 3px;
    }

    .msg-botm {
      width: 326px;
      margin: 0 0 0 1px;
      font-size: 15px;
      color: #555;
      line-height: 1.4em;
      padding-top: 10px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .swiper-container {
    position: absolute;
    top: 0;
    margin: 1px auto;

    .swiper-wrapper {
      margin: 0;
      padding: 0 0 0 30px;

      .swiper-slide {
        font-size: 15px;
        left: -20px;
      }
    }
  }

  .ft_icon_close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 11px;
    top: 8px;
    z-index: 1999;
  }

  .msg_hr {
    position: absolute;
    left: 14px;
    top: 31px;
    width: 326px;
    border: none;
    height: 1px;
    background-color: #d0d0d0;
    z-index: 1999;
  }

  .pagination_wrap { /* 2022-11-01  추가  */
    position: relative;
    height: 20px;
    bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .swiper-pagination { /* 2022-11-01  추가  */
    position: relative;
    background: transparent;
    margin: 0 auto;
    opacity: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .swiper-pagination-bullet { /* 2022-11-01  추가  */
    width: 6px;
    height: 6px;
    background: #d0d0d0;
    margin-right: 6px !important;
    border-radius: 5px !important;
    border: 1px solid #d0d0d0;
    opacity: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .swiper-pagination-bullet-active { /* 2022-11-01  추가  */
    width: 6px;
    /* transition: width .5s;  */
    border-radius: 6px;
    background: #333;
    border: 1px solid #333;
  }
}

/* ------ END 플로팅 배너 2022-10-30 ----- */

/* ------ whatsapp ----- */
.what_app {
  position: fixed;
  z-index: 99;
  bottom: 58px;
  box-sizing: border-box;
}

.what_app {
  width: 70px;
  right: 20px;
}

/* mobile */
@media (min-width: 768px) {
  .what_app {
    width: 70px;
    right: 45px;
  }
}

/* i pad */
@media (min-width: 992px) {
  .what_app {
    width: 80px;
    right: 60px;
  }
}

/* PC */
.what_app img {
  border-radius: 40px;
  box-shadow: 1px 5px 16px rgba(0, 0, 0, 0.2)
}

/* ------ END whatsapp ----- */
