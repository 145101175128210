// -----------------------------------------------------------------------------
// Shelf
// -----------------------------------------------------------------------------

.shelf {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: 100%;
    opacity: 0;

    & + & {
      margin-top: rem(55);
    }
  }

  &__link {
    display: block;
  }

  &__thumb {
    width: 100%;
    position: relative;
    display: block;

    img {
      width: 100%;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    color: $white;
    left: 0;
    bottom: 0;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    padding: 15% rem(20) rem(20);
    font-size: rem(26);
    text-align: right;
    line-height: 1;

    &::after {
      content: '';
      @include full-pos;
      z-index: -1;
      @include gradient('0deg', rgba($gray9, 0.2), rgba($gray9, 0));
    }

    &--instagram {
      @include icon('sns-instagram');
    }
    &--blog {
      @include icon('sns-blog');
    }
    &--youtube {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      font-size: rem(40);
      @include full-pos;
      @include icon('play-round') {
      }

      &::after {
        background: rgba($gray9, 0.2);
      }
    }
  }

  &__cont {
    margin-top: rem(22);
  }

  &__h {
    display: block;
    margin: rem(15) 0 rem(10);
    @include break-word;
  }

  &__date {
    display: block;
    margin-top: rem(15);
    color: $gray5;
    font-size: rem(15);
  }

  @include mq(large) {
    &__item {
      width: calc(33.33% - 3.53%);

      &:nth-child(-n + 3) {
        margin-top: 0;
      }

      &:nth-child(n + 4) {
        margin-top: rem(95);
      }
    }

    &__cont {
      margin-top: rem(25);
    }

    &__gutter {
      width: 5.3%;
    }
  }

  // variable
  &--square {
    @include mq($until: large) {
      .shelf {
        &__item {
          width: calc(50% - 10px);

          &:nth-child(2) {
            margin-top: 0;
          }

          &:nth-child(n + 3) {
            margin-top: 20px;
          }
        }

        &__icon {
          padding: 15% rem(10) rem(10);
        }

        &__gutter {
          width: 20px;
        }
      }
    }

    @include mq(large) {
      .shelf {
        &__item {
          &:nth-child(-n + 3) {
            margin-top: 0;
          }

          margin-top: rem(60);
        }
      }
    }
  }
}

.shelf-func {
  margin-top: rem(40);
  text-align: center;

  @include mq(large) {
    margin-top: rem(40);
  }
}
