// -----------------------------------------------------------------------------
// tingle - Modal Popup
// -----------------------------------------------------------------------------
// @import 'tingle.js/src/tingle.css';

.tingle-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  visibility: hidden;
  overflow: hidden;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  opacity: 0;
  background: rgba($gray9, .3);
  align-items: center;
  -webkit-overflow-scrolling: touch;

  &__close {
    display: none;
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 1000;
    padding: 0;
    width: 2rem;
    height: 2rem;
    color: $gray9;
    cursor: pointer;
    font-size: 26px;

    &Icon {
      display: none;
    }

    &Label {
      display: none;
    }
  }

  &-box {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    cursor: auto;
    // width: 0%;
    // background: #fff;
    opacity: 1;
    flex-shrink: 0;
    // will-change: transform, opacity;

    &__content {
      padding: 0 !important;
    }
  }

  // state
  &.tingle-enabled {
    position: fixed;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  &.tingle-modal--visible {
    visibility: visible;
    opacity: 1;

    .tingle-modal-box__footer {
      bottom: 0;
    }

    .tingle-modal-box {
      animation: scale .2s cubic-bezier(.68, -.55, .265, 1.55) forwards;
    }
  }

  &.tingle-modal--overflow {
    overflow-y: scroll;
  }

  @keyframes scale {
    0% {
      transform: scale(.9);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

.modal {
  position: relative;
  z-index: 10;
  width: 100%;
  min-width: $w-small;
  height: 100vh;
  font-size: rem(16);
  font-weight: 400;
  line-height: 1.6;
  color: $gray9;
  background: $white;
  @include scrollbars('.modal-body', 4px, $gray3, $gray0);

  &::after {
    content: '';
    position: absolute;
    z-index: 20;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 75px;
    transition: all .1s;
    opacity: 0;
    @include gradient('0deg', $white 50%, rgba($white, 0));
  }

  &.has-scroll {
    &::after {
      opacity: 1;
    }
  }

  &-wrap {
    display: none;
  }

  &-header {
    box-sizing: border-box;
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  &-close {
    position: fixed;
    display: block;
    z-index: 1000;
    top: 15px;
    right: 25px;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    font-size: 26px;
    color: $gray9;
    @include icon('close');
  }

  &-body {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 0 20px;
  }

  &-footer {
    position: absolute;
    z-index: 50;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    text-align: center;
    background: $white;
  }

  @include mq(medium) {
    &-body {
      margin: 0 20px;
      padding: 0;
      font-size: rem(18);
    }
  }
}

.base-modal {
  $pop-width-max: 600px;
  $pop-height-max: 600px;
  $pop-height-base: 100vh;
  $pop-header-height: 100px;

  .modal {
    &-header {
      height: $pop-header-height;
    }
    &-body {
      max-height: calc(#{$pop-height-base} - #{$pop-header-height} - 100px);
    }
    @include mq(medium) {
      width: $pop-width-max;
      max-height: $pop-height-max;

      &-body {
        max-height: calc(#{$pop-height-max} - #{$pop-header-height} - 100px);
      }
    }
  }
}
