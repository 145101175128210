// -----------------------------------------------------------------------------
// Accordion
// -----------------------------------------------------------------------------

.accordion {
  border-top: 1px solid $list-border-dark-color;

  .item {
    border-bottom: 1px solid $list-border-color;

    &-toggle {
      display: block;
      position: relative;
      width: 100%;
      padding: 19px 0;
      box-sizing: border-box;
      text-align: left;
      @include icon('arrow-up', both);

      span {
        position: relative;
        padding-bottom: 3px;
        color: $gray9;
        font-weight: bold;
        transition: color $dur;
      }

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -10px;
        font-size: 16px;
        transition: all $dur;
        transform: rotate(180deg);
      }

      &::after {
        opacity: 0;
      }
    }

    &-cont {
      box-sizing: border-box;
      padding: 2rem 1rem 2rem;
    }
  }

  .is-active {
    .item {
      &-toggle {
        &::before,
        &::after {
          transform: none;
        }

        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 1;
        }

        span {
          color: $gray9;
        }
      }
    }
  }
}

.depth-accordion {
  // margin-top: rem(35);
  border-bottom: 1px solid rgba($gray9, 0.2);

  &__item {
    border-top: 1px solid rgba($gray9, 0.2);
  }

  &__toggle {
    position: relative;
    display: block;
    width: 100%;
    padding: rem(20) 0;
    text-align: left;
    overflow: hidden;

    .ie &,
    .edge & {
      &:focus {
        outline: 1px dotted $gray9;
      }
    }

    @include icon('arrow-down') {
      position: absolute;
      top: 20px;
      right: 7px;
      font-size: rem(25);
      line-height: 1;
      transition: transform $dur $ease;

      .is-active & {
        transform: rotate(-180deg);
      }
    }

    .toggle {
      &__em {
        display: block;
        margin-bottom: rem(10);
        padding-right: rem(40);
      }

      &__sub {
        color: $gray6;
      }

      &__h {
        margin-bottom: rem(10);
        font-size: rem(16);
        font-weight: 400;
      }

      &__date,
      &__type {
        color: $gray6;
      }

      &__type {
        margin-left: rem(10);
      }
    }
  }

  &__cont {
    padding: rem(10) 0 rem(40);
  }

  &__dl {
    dt {
      margin-bottom: 4px;
    }

    dd {
      & + dt {
        margin-top: rem(20);
      }
    }
  }

  &__func {
    margin-top: rem(35);
  }

  @include mq(medium) {
    &__toggle {
      .toggle {
        &__cont {
          display: flex;
        }

        &__h,
        &__date,
        &__type {
          font-size: rem(18);
        }

        &__h {
          box-sizing: border-box;
          width: 58%;
          margin-bottom: 0;
          padding-right: 5%;
        }

        &__date {
          width: 23%;
        }

        &__type {
          width: 11%;
        }
      }
    }

    &__dl {
      padding-right: 10%;
    }
  }

  @include mq(large) {
    // margin-top: rem(56);

    &__item {
      padding: 0 rem(30);
    }

    &__toggle,
    &__dl {
      box-sizing: border-box;
      padding-left: 16%;
    }

    &__toggle {
      padding-top: rem(25);
      padding-bottom: rem(25);

      &::before {
        top: 27px;
        right: 5px;
      }

      .toggle {
        &__em {
          position: absolute;
          top: rem(25);
          left: 0;
        }
      }
    }

    &__cont {
      padding-top: rem(15);
      padding-bottom: rem(60);
    }

    &__dl {
      margin-top: rem(6);
      display: flex;
      flex-wrap: wrap;
      margin-top: rem(6);

      dt,
      dd {
        margin-bottom: rem(15);
      }

      dt {
        width: 115px;
      }

      dd {
        width: calc(100% - 115px);

        & + dt {
          margin-top: 0;
        }
      }
    }

    &__func {
      margin-top: rem(45);
    }
  }
}

