.modal {
  &.modal-confirm {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: $header-zindex + 20;
    width: calc(100% - 40px);
    min-width: 260px;
    max-width: 440px;
    height: auto;
    background-color: $white;
    transform: translate(-50%, -50%);

    &::before {
      content: '';
      position: fixed;
      top: 0;
      bottom: 0;
      background-color: rgba($gray9, 0.2);
    }

    &::after {
      display: none;
    }

    .modal {
      &-close {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      &-body {
        padding: rem(65) 0 rem(45);
        text-align: center;

        p {
          margin-top: 0;

          &:only-child {
            margin: 0;
          }
        }
      }

      &-footer {
        position: static;
        display: flex;
        padding: 0;
        overflow: hidden;

        .btn {
          width: 50%;
          margin-left: 0;

          &:only-child {
            width: 100%;
          }

          &--close {
            border: none;
            background-color: $gray0;
            color: $gray6;

            &:hover,
            &:focus {
              color: $white;
            }
          }
        }
      }
    }
  }
}
