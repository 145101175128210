// -----------------------------------------------------------------------------
// GNB
// -----------------------------------------------------------------------------

.gnb {
  font-family: $font-sub-kor;
  font-weight: 500;

  [lang='en'] & {
    font-family: $font-main-eng;
  }

  .d1 {
    &__item {
      display: block;
      color: $gray9;
    }
  }

  .d2 {
    &__item {
      display: block;
      -webkit-tap-highlight-color: transparent;
    }

    &.is-current,
    &.is-active {
      .d2__text {
        background-size: 100% 100%;

        .mobile & {
          // for @ios
          background-size: 0% 0%;
          border-bottom: 1px solid $gray7;
        }
      }
    }
  }

  .d3 {
    &__item {
      display: block;
      font-family: $font-main-kor;
      color: $gray6;
      font-weight: 400;
      text-transform: capitalize;
    }

    &__text {
      background-image: linear-gradient(
        transparent calc(100% - 1px),
        $gray6 1px
      );
    }

    &.is-current {
      .d3__text {
        background-size: 100% 100%;
      }
    }
  }

  // ~large
  @include mq($until: large) {
    display: none;
    position: fixed;
    top: $header-height-sm;
    left: 0;
    right: 0;
    bottom: 60px;

    &__pad {
      overflow-y: auto;
      box-sizing: border-box;
      height: 100%;
      background-color: $white;
    }

    &__lang {
      position: fixed;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 60px;
      padding: 0 $offset-sm;
      background-color: $white;
      font-family: $font-sub-kor;
      font-size: rem(15);
      font-weight: 700;
      box-shadow: 0px -10px 30px 0px rgba($gray9, 0.1);

      [lang='en'] & {
        font-family: $font-main-eng;
      }

      .lang {
        &__item {
          display: inline-block;
          color: $gray3;
          line-height: 1;
          position: relative;
          margin-left: rem(10);
          padding-left: rem(10);

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            width: 1px;
            height: 11px;
            background-color: rgba($gray9, 0.2);
          }
        }
      }
    }

    .has-sublist {
      position: relative;

      @include icon('arrow-down-bold') {
        position: absolute;
        font-size: rem(18);
        pointer-events: none;
        transition: $dur $ease;
      }

      &.is-active {
        &::before {
          transform: rotate(-180deg);
        }
      }
    }

    .d1 {
      &::before {
        top: 20px;
        right: $offset-sm;
      }

      &__item {
        position: relative;
        padding: rem(20) $offset-sm;
        font-size: rem(18);
        font-weight: 700;
      }

      &.is-current {
        .d1__text {
          width: calc(100%);
          background-image: linear-gradient(
            transparent calc(100% - 1px),
            $gray9 1px
          );
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .d2-list {
      background-color: $gray1;
      padding: 0 $offset-sm;
    }

    .d2 {
      & + .d2 {
        border-top: 1px solid rgba($gray9, 0.1);

        .d2__item {
          color: $gray7;
          font-weight: 500;
        }
      }

      &::before {
        top: 13px;
        right: 0;
      }

      &__item {
        font-size: rem(16);
        width: 100%;
        padding: rem(15) 0;
        text-align: left;
        font-weight: 700;
      }
    }

    .d3-list {
      margin-top: rem(0);
      padding: 0 0 rem(10) rem(20);
    }

    .d3 {
      &__item {
        padding: rem(7) 0;
        font-size: rem(15);

        & + & {
          padding-top: rem(7);
        }
      }
    }

    .is-active & {
      display: block;
      // transform: translateX(0%);
    }
  }

  // medium ~ large
  @include mq(medium, $until: large) {
    width: 60%;
    max-width: 490px;

    &__pad {
      width: 100%;
    }

    &__lang {
      padding: 0 $offset-md;
      width: 60%;
      max-width: 490px;
    }

    .d1 {
      &::before {
        right: $offset-md;
      }

      &__item {
        padding-left: $offset-md;
        padding-right: $offset-md;
      }
    }

    .d2-list {
      padding-left: $offset-md;
      padding-right: $offset-md;
    }
  }

  // ~ large
  @include mq(large) {
    &__pad {
      height: 100%;
    }
    .d1-list {
      position: relative;
      display: flex;
      height: 100%;
    }

    .d1 {
      position: relative;
      height: 100%;

      &__item {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 rem(25);
        font-size: rem(17);
        // color: $color-primary !important;

        &:hover,
        &:focus {
          font-weight: 700;
        }
      }

      &.is-active {
        .d2-list {
          display: flex;
        }
      }

      &--about {
        .d2-list {
          transform: translateX(-134px);
        }
      }

      &--product {
        .d2-list {
          transform: translateX(-35px);
        }

        .d2 + .d2 {
          margin-left: rem(55);
        }
      }

      &--innovation {
        .d2-list {
          transform: translateX(-10px);
        }
      }

      &--story {
        .d2-list {
          transform: translateX(100px);
        }
      }

      &--esg {
        .d2-list {
          transform: translateX(150px);
        }
      }

      &--career {
        .d2-list {
          transform: translateX(190px);
        }
      }
    }

    .sub-wrap {
      position: fixed;
      top: $header-height-lg;
      left: 0;
      right: 0;
      background-color: rgba($white, 0);
      transition: $dur $ease;
    }

    .d2-list {
      width: calc(100% - #{$offset-lg * 2});
      max-width: $w-wrap;
      display: none;
      justify-content: center;
      margin: auto;
    }

    .d2 {
      & + .d2 {
        margin-left: rem(40);
      }

      &__item {
        font-size: rem(16);
        padding: rem(21) 0;
      }

      &.has-sublist {
        .d2 {
          &__item {
            padding-bottom: rem(3);
          }
        }
      }
    }

    .d3-list {
      padding-bottom: rem(30);
    }

    .d3 {
      &__item {
        padding: rem(5) 0;
        font-size: rem(15);
      }
    }
  }
}

#gnb-line {
  @include mq(large) {
    position: absolute;
    bottom: 0px;
    display: block;
    height: 3px;
    background-color: $gray9;
    width: 0;
    pointer-events: none;
  }
}
