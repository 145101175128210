// -----------------------------------------------------------------------------
// Form > Radio, Checkbox
// -----------------------------------------------------------------------------

.radio,
.checkbox {
  position: relative;
  display: inline-block;
  margin-right: 25px;
  white-space: normal;
  @include break-word();

  label {
    display: inline-block;
    position: relative;
    padding: 4px 0;
    padding-left: 24px;
    transition: color 0.3s;
    font-size: rem(16);
    font-weight: 100;
    line-height: 1;
    vertical-align: top;
    color: $gray6;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 4px;
      font-size: 17px;
      line-height: 1;
    }
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border: 2px solid $gray5;
      transition: border-color 0.2s;
    }
    &::after {
      color: transparent;
      transition: background-color 0.2s;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  input {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;

    &:hover + label,
    &:focus + label {
      color: $gray9;

      &::before {
        border-color: $gray9;
      }
    }

    &:focus + label {
      outline: 1px dotted $silver-dark;
    }

    &:checked {
      & + label {
        color: $gray9;
        &::before {
          background: $color-secondary;
          border-color: $color-secondary;
        }
        &::after {
          background: transparent;
          background-color: $white;
        }
      }
    }

    &[disabled] {
      & + label {
        cursor: not-allowed;
        color: $silver-dark;
        &::before {
          border-color: $silver-dark !important;
        }
      }
    }
  }
}

.radio {
  &.input-block + &.input-block {
    margin-top: 5px;
  }
  [type='radio'] {
    & + label {
      &::before {
        border-radius: 50%;
      }

      &::after {
        content: '';
        background: transparent;
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        top: 9px;
        left: 5px;
        border-radius: 50%;
      }
    }
  }
}

.checkbox {
  [type='checkbox'] {
    & + label {
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 4px;
        height: 8px;
        top: 13px;
        left: 4px;
        transform: scaleX(-1) rotate(135deg);
        transform-origin: left top;
        border-top: 2px solid;
        border-right: 2px solid;
      }
    }

    &:checked {
      & + label {
        &::after {
          border-color: $white;
          background-color: transparent;
        }
      }
    }
  }

  &--hidden-label {
    .checkbox {
      &__text {
        display: inline-block;
        overflow: hidden;
        width: 1px;
        text-indent: 2px;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }
}
