$white: #FFF;
$gray0: #F7F7F7;
$gray1: #EFEFEF;
$gray2: #D8D8D8;
$gray3: #B2B2B2;
$gray4: #A0A0A0;
$gray5: #76777B;
$gray6: #54565B;
$gray7: #404148;
$gray8: #2B2D35;
$gray9: #101820;
$fog: $gray0;
$ash: $gray1;
$silver-light: $gray2;
$silver: $gray3;
$silver-dark: $gray4;
$gray-light: $gray5;
$gray: $gray6;
$gray-dark: $gray7;
$dark: $gray8;
$ink: $gray9;
$color-primary-white: $white;
$color-primary-pale: $gray3;
$color-primary-light: $gray6;
$color-primary: $gray9;
$color-secondary: #0071E3;
$color-secondary-light: #2997FF;
$color-secondary-dark: #0066CC;
$color-error: #E81425;