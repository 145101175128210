// -----------------------------------------------------------------------------
// Form > Dropdown
// -----------------------------------------------------------------------------

.dropdown {
  $height: 20px;
  $option-padding-tb: 12px;

  position: relative;
  display: inline-block;
  font-size: 16px;
  text-align: left;
  @include scrollbars(
    '.dropdown-options',
    7px,
    darken($silver-light, 5%),
    lighten($silver-light, 10%)
  );

  &-selector {
    box-sizing: border-box;
    width: 100%;
    padding: $input-padding-tb $input-padding-lr;
    padding-right: $input-padding-lr + 20px;
    border: 1px solid $silver-light;

    background: $white;
    color: $gray-dark;
    line-height: $height;
    text-align: left;

    outline: none;
    vertical-align: middle;
    // -webkit-border-radius: 0;
    // -webkit-appearance: none;
    transition: border-color 0.3s;

    @include icon('arrow-down-bold', after) {
      color: $gray9;
      transition: transform $dur $ease;

      .is-opened & {
        transform: rotate(-180deg);
      }
    }

    &::after {
      position: absolute;
      top: 16px;
      right: 15px;
      font-size: 16px;
    }

    .no-touchevents & {
      &:hover,
      &:focus {
        border-color: $gray9;
      }
    }
  }

  &-value {
    position: relative;
    display: block;
    height: $height;
    letter-spacing: -0.025em;
    @include ellipsis(1);
    @include break-word();
  }

  &-popup {
    position: absolute;
    left: 0;
    right: 0;
    display: none;
    overflow: hidden;
    margin-top: 15px;
    z-index: 1;
    transition: all 0.3s;
  }

  &-options {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    border: 1px solid $gray1;
    background: $white;
    max-height: 270px;

    > li {
      position: relative;
      min-height: $height + ($option-padding-tb * 2);

      > a,
      > label {
        box-sizing: border-box;
        display: block;
        float: none;
        padding: $option-padding-tb $input-padding-lr;
        padding-right: $input-padding-lr * 2;
        text-align: left;
        line-height: $height;
        text-decoration: none;

        &:hover,
        &:focus {
          outline: none;
          background: $gray0;
          // color: $white;
        }
      }

      input {
        position: absolute;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;

        &:checked + label {
          color: $color-primary;
        }
        &[disabled] + label {
          cursor: not-allowed;
          color: $silver;

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }

      &.is-selected {
        > a,
        input:checked + label {
          color: $color-secondary;
          background: transparent;

          &:hover {
            background: $gray0;
          }
        }

        @include icon('check', before) {
          position: absolute;
          font-size: 16px;
          top: 50%;
          right: 15px;
          margin-top: -12px;
          color: $color-secondary;
        }
      }

      &.is-disabled {
        > a,
        > label {
          cursor: not-allowed;
          color: $silver-dark;

          &:hover,
          &:focus {
            background: transparent;
          }
        }
      }
    }
  }

  &.is-opened {
    .dropdown {
      &-selector {
        border-color: $dark;
      }

      &-popup {
        display: block;
      }

      &-options {
        display: block;
        // border-color: $dark;
      }
    }
  }

  &.is-disabled {
    .dropdown {
      &-selector {
        cursor: not-allowed;
        color: $silver-dark;
        background: $fog;

        &:hover {
          border-color: $silver-light;
        }

        &::after {
          color: $gray-light;
        }
      }
    }
  }
}
