// -----------------------------------------------------------------------------
// Swiper - Slider
// -----------------------------------------------------------------------------

@import 'swiper/dist/css/swiper.min.css';

.swiper {
  &-controller {
    position: absolute;
    bottom: 4vh;
    left: 0;
    right: 0;

    &__pad {
      display: flex;
      align-items: center;
    }

    &--invert {
      .swiper-button-prev,
      .swiper-button-next,
      .swiper-button-play,
      .swiper-button-pause,
      .swiper-pagination {
        color: $white;
      }

      .swiper-pagination-bullet {
        border-color: $white;

        &-active {
          background-color: transparent;
        }
      }
    }
  }

  &-pagination {
    position: relative;
    z-index: 15;
    display: flex;
    text-align: left;

    &-bullet {
      position: relative;
      width: 40px;
      border: none;
      border-radius: 0;
      margin-right: rem(10);
      transition: opacity 0.2s $ease, color 0.2s $ease;

      background-color: transparent;
      border-bottom: 3px solid white;
      height: 30px;

      .no-touchevents & {
        &:hover,
        &:focus {
          background-color: transparent;
          opacity: 1;
        }
      }

      &.has-thumb {
        width: 100px;
        height: auto;
        border-radius: 0;
      }
    }
  }

  &-button {
    &-prev,
    &-next {
      $btn-size: 40px;
      background-image: none;
      width: $btn-size;
      height: $btn-size;
      line-height: ($btn-size - 2);
      text-align: center;

      &::before {
        font-size: 13px;
      }

      @include mq(medium) {
        $btn-size: 50px;
        width: $btn-size;
        height: $btn-size;
        line-height: ($btn-size - 2);

        &::before {
          font-size: 16px;
        }
      }
    }

    // &-prev {
    //   left: 10px;
    //   @include icon('slide-prev');
    // }

    // &-next {
    //   right: 10px;
    //   @include icon('slide-next');
    // }

    &-play,
    &-pause {
      display: none;
      width: 40px;
      height: 40px;
      position: relative;
      z-index: 1;
      transition: color $dur;

      &--invert {
        color: $white;
      }

      &::before {
        vertical-align: top;
        font-size: 12px;
        margin-top: 8px;
      }
    }

    &-play {
      @include icon('play');
      .is-pause & {
        display: block !important;
      }
    }

    &-pause {
      position: relative;
      @include icon('pause');

      .swiper-container-initialized & {
        display: block;
      }

      .is-pause & {
        display: none !important;
      }
    }
  }

  // vertical
  &-container-vertical {
    .swiper-controller {
      left: auto;
      top: 0;
      bottom: 0;
      right: 25px;

      &--invert {
        .swiper-pagination-bullet {
          border-color: $white;
          color: $white;

          &-active {
            background-color: transparent;
          }
        }
      }
    }

    .swiper-pagination {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__text {
        margin-right: rem(10);
        font-size: rem(14);
        line-height: 1;
        pointer-events: none;
      }

      &-bullet {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: auto;
        height: 40px;
        border-right: 3px solid $gray9;
        border-bottom: 0;
        margin-right: 0;
        margin-bottom: 1rem;
        cursor: pointer;
        font-family: $font-main-eng;
        outline: none;
      }
    }
  }

  @include mq($until: medium) {
    &-container-vertical {
      .swiper-controller {
        right: 10px;
      }

      .swiper-pagination {
        width: 40px;

        &__text {
          display: none;
        }

        &-bullet {

        }
      }
    }
  }

  @include mq(medium) {
    &-controller {
      bottom: 14vh;
    }
  }
}
