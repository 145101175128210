@media only screen and (min-width: 480px) and (max-height: 450px) and (orientation: landscape) {
  body {
    height: 100vh;
    overflow: hidden;
  }

  #landscape-veil {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $header-zindex + 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 35px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-image: url(../../image/common/a/locknlock-logo.svg);
    }

    &::after {
      display: block;
      margin-top: rem(25);
      color: $gray9;
      text-align: center;
      font-size: 20px;
      line-height: 1.2;
      @include break-word;
    }

    &:lang(ko) {
      &::after {
        content: '모바일에서는 세로로 감상해주세요.';
      }
    }

    &:lang(en) {
      &::after {
        content: 'You can check contents in portrait view on mobile.';
      }
    }
  }
}
