// -----------------------------------------------------------------------------
// Form > Flex group
// -----------------------------------------------------------------------------

.flex-g {
  .form-g {
    margin-top: 0;

    & + .form-g {
      margin-top: rem(10);
    }
  }

  @include mq(medium) {
    display: flex;

    input,
    select {
      max-height: 50px;
    }

    .form-g {
      margin-top: 0;

      & + .form-g {
        margin-top: 0;
         margin-left: rem(9);
      }
    }

    &--2 {
      .form-g {
        width: calc(#{percentage(1/2)} - 4.5px);
      }
    }

    &--3 {
      .form-g {
        width: calc(#{percentage(1/3)} - 6px);
      }
    }
  }
}
