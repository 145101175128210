// -----------------------------------------------------------------------------
// Helper Classes
// -----------------------------------------------------------------------------

template,
.hidden,
[hidden] {
  display: none !important;
}

.a11y {
  position: absolute !important;
  overflow: hidden;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

@include mq($until: medium) {
  .for-medium {
    display: none !important;
  }
}
@include mq($until: large) {
  .for-large {
    display: none !important;
  }
}
@include mq(medium) {
  .for-until-medium {
    display: none !important;
  }
}
@include mq(large) {
  .for-until-large {
    display: none !important;
  }
}

