// -----------------------------------------------------------------------------
// Wrapper
// -----------------------------------------------------------------------------

%layout-default {
  position: relative;
  width: calc(100% - #{$offset-sm * 2});
  margin-left: auto;
  margin-right: auto;

  @include mq(medium) {
    width: calc(100% - #{$offset-md * 2});
  }

  @include mq(x-large) {
    width: calc(100% - #{$offset-lg * 2});
  }
}

.l-wrap {
  @extend %layout-default;
  @include mq(x-large) {
    max-width: $w-wrap;
  }
}

.l-content {
  @extend %layout-default;
  @include mq(x-large) {
    max-width: $w-default;
  }
}

.l-narrow {
  @extend %layout-default;
  max-width: $w-narrow;
}

.l-m-full {
  @include mq($until: large) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
