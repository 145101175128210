// -----------------------------------------------------------------------------
// Badge
// -----------------------------------------------------------------------------

.badge {
  display: inline-block;
  padding: 5px 9px;
  background-color: $color-primary-light;
  color: $white;
  font-family: $font-sub-kor;
  font-size: 1.4rem;
  line-height: 1.4;
  font-style: normal;

  [lang='en'] & {
    font-family: $font-main-eng;
  }

  & + & {
    margin-left: $element-margin-lr;
  }
}
