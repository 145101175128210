// -----------------------------------------------------------------------------
// Button
// -----------------------------------------------------------------------------

// button element style reset
// -----------------------------------------------------------------------------
// Remove the inner border and padding in Firefox.
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

// Restore the focus styles unset by the previous rule.
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

// button component style
// -----------------------------------------------------------------------------
.btn {
  box-sizing: border-box;
  display: inline-block;
  overflow: visible;
  position: relative;
  padding: 14px 29px;
  border: 1px solid $gray9;
  background-color: transparent;
  color: $gray9;
  font-size: 17px;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  vertical-align: middle;
  transition: color 0.28s $ease;
  line-height: 1.2;

  & + & {
    margin-left: $element-margin-lr;
  }

  .btn__text,
  span {
    position: relative;
    z-index: 1;
    display: inline-block;
    pointer-events: none;
  }

  &::before {
    @include full-pos();
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    content: '';
    background-color: $color-secondary;
    transition: transform 0.28s $ease;
    transform: scale(0, 1);
    transform-origin: right center;
  }

  &::after {
    position: relative;
    transition: transform 0.28s $ease;
  }

  .no-touchevents & {
    &:hover,
    &:focus {
      text-decoration: none;
      color: $white;

      &::before {
        transform: scale(1, 1);
        transform-origin: left center;
      }
    }
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover,
    &:focus {
      color: inherit;
      border-color: inherit;
      background-color: inherit;

      &::before {
        transform: scale(0, 1);
      }
    }
  }

  // &:active:enabled {
  // transform: scale(0.97);
  // }

  // size variation
  &--sm {
    padding: 8.5px 19px;
    font-size: 16px;
  }

  &--lg {
    padding: 17.5px 39px;
    font-size: 18px;
  }

  &--block {
    display: block;
    width: 100%;
  }

  // color variation
  &--em {
    background-color: $gray9;
    color: $white;

    &[disabled] {
      &:hover,
      &:focus {
        color: $white;
        border-color: $gray9;
        background-color: $gray9;
      }
    }
  }

  &--ghost {
    border-color: $white;
    color: $white;

    &[disabled] {
      &:hover,
      &:focus {
        color: $white;
        border-color: $white;
      }
    }
  }

  &--invert {
    background-color: $white;
    border-color: $white;

    &[disabled] {
      &:hover,
      &:focus {
        border-color: $white;
        background-color: $white;
      }
    }
  }
}

// button with icon
.btn-more {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  border: 1px solid $gray9;
  transition: 0.25s $ease;

  @include icon(plus) {
    font-size: 16px;
    transition: 0.3s $ease;
    color: $gray9;
  }

  &:hover,
  &:focus {
    background-color: $gray9;

    &::before {
      transform: rotate(90deg);
      color: $white;
    }
  }
}

.btn-download {
  @include icon('download', after) {
    margin: rem(-1) 0 0 rem(4);
    vertical-align: middle;
    line-height: 1;
  }
}

.btn-add {
  padding-left: rem(40);

  @include icon('plus', after) {
    position: absolute;
    top: 10px;
    left: 18px;
  }
}

.btn-del {
  padding-left: rem(40);

  &::after {
    content: '';
    position: absolute;
    top: 17px;
    left: 18px;
    width: 12px;
    height: 2px;
    background-color: $gray9;
  }

  .no-touchevents & {
    &:hover,
    &:focus {
      &::after {
        background-color: $white;
      }
    }
  }
}

.btn-refresh {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background-color: $white;
  border: 1px solid $gray2;
  color: $gray2;
  @include icon('refresh', after);
  transition: border-color $dur $ease, color $dur $ease;

  &:hover,
  &:focus {
    color: $gray9;
    border-color: $gray9;
  }
}

.btn-sound {
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  background-color: $white;
  border: 1px solid $gray2;
  color: $gray2;
  @include icon('sound', after);
  transition: border-color $dur $ease, color $dur $ease;

  &:hover,
  &:focus {
    color: $gray9;
    border-color: $gray9;
  }
}
