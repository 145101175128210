// -----------------------------------------------------------------------------
// Form > Input group
// -----------------------------------------------------------------------------

.input-g {
  $padding-tb: 10px;
  $padding-lr: 14px;
  $bottom-space: 5px;
  position: relative;

  &__item {
    & + & {
      margin-top: rem(40);
    }
  }

  &__pad {
    margin-bottom: rem(20);
    padding-bottom: rem(25);
    border-bottom: 1px solid $gray2;
  }

  &__func {
    text-align: right;
  }

  &.is-disabled {
    .input-g {
      &__func {
        display: none;
      }
    }
  }

  &__item {
    & + & {
      margin-top: rem(60);
    }
  }

  @include mq(medium) {
    &__pad {
      padding-bottom: rem(30);
    }
  }

  // with button
  // -----------------------------------------------------------------------------
  &--btn {
    $input-btn-size: 125px;

    > input {
      width: calc(100% - #{$input-btn-size} - 10px);
    }

    .btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0;
      width: $input-btn-size;
      height: 50px;
      line-height: 50px;
    }

    .ta {
      & + .btn {
        height: 100%;
      }
    }

    @include mq(medium) {
      .ta {
        width: calc(100% - #{$input-btn-size});
      }
    }

    @include mq($until: large) {
      &.form-g {
        .btn {
          top: 30px;
        }
      }
    }

    @include mq($until: small) {
      .ta {
        textarea {
          margin-bottom: 0;
        }

        & + .btn {
          position: relative;
          width: 100%;
        }
      }
    }
  }

  // with button
  // -----------------------------------------------------------------------------

  // group type
  // -----------------------------------------------------------------------------
  &--search {
    [type='text'],
    [type='search'] {
      padding-right: 55px;
    }

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: $padding-tb 0;
      width: 45px;
      background: $gray8;
      color: $white;
      border-left: 1px solid $silver;
      font-size: 20px;
      @include icon('search');
    }
  }

  &--searchborder {
    [type='text'],
    [type='search'] {
      padding-right: 76px;
    }

    button[type='submit'] {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $padding-tb 2.5px;
      width: 45px;
      color: $gray9;
      font-size: 24px;
      @include icon('search');
    }

    button[type='button'] {
      position: absolute;
      top: 0;
      right: 50px;
      width: 20px;
      height: 50px;

      @include icon('close') {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: $gray2;
        border-radius: 50%;
        color: $white;
        font-size: 12px;
      }
    }
  }

  &--searchline {
    border-bottom: 2px solid $gray9;

    [type='text'],
    [type='search'] {
      background: transparent;
      padding-right: 55px;
      border: 0;
    }

    button {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding: $padding-tb 0;
      width: 45px;
      font-size: 20px;
      @include icon('search');
    }
  }

  &--email {
    .pad {
      $i-width: 30px;
      display: flex;
      margin-bottom: $bottom-space;

      input {
        width: calc(50% - #{$i-width}/ 2);
      }

      .i {
        display: inline-block;
        width: $i-width;
        padding-top: 8px;
        text-align: center;
        vertical-align: middle;
        font-style: normal;
      }
    }
  }

  &--currency {
    input {
      padding-left: $padding-lr + 20px;
    }

    .currency {
      &-symbol {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 14px;
        margin-top: -8px;
        vertical-align: top;
        line-height: 1;
      }
    }
  }

  &--quantity {
    $input-w: 46px;
    $btn-w: 46px;

    width: $input-w + ($btn-w * 2);

    input {
      width: $input-w;
      margin: 0 $btn-w;
      text-align: center;
      border-left: 0;
      border-right: 0;

      &:hover,
      &:focus {
        border-color: $silver;
      }
    }

    .quantity {
      &-dec,
      &-inc {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        line-height: 1;
        width: $btn-w;
        text-align: center;
        vertical-align: top;
        padding: 10px 0;
        border: 1px solid $silver;
      }

      &-dec {
        left: 0;
        border-right: 0;
        // @include icon('bold-minus', before);
      }

      &-inc {
        right: 0;
        border-left: 0;
        // @include icon('bold-plus', before);
      }
    }
  }

  &--daterange {
    display: flex;
    flex-wrap: wrap;

    .flatpickr {
      width: calc(50% - 5px);
      margin-right: 10px;

      & + .flatpickr {
        margin-right: 0;
      }
    }

    .period {
      margin-top: 10px;
      display: flex;
      width: 100%;
      border-left: 1px solid $gray3;

      button {
        position: relative;
        text-align: center;
        color: $gray7;
        border: 1px solid $gray3;
        border-left: 0;
        flex: 1;
        padding: 10px 0;
        font-size: 1.4rem;

        &.is-selected {
          font-weight: 700;
          color: $white;
          border-color: $dark;
          background-color: $dark;
          font-weight: normal;
          box-shadow: none;
        }
      }
    }

    @include mq($until: large) {
      position: relative;

      .flatpickr {
        width: calc(50% - 4px);
      }

      .flatpickr + .flatpickr {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 9;
      }
    }
  }

  &--file {
    [type='text'] {
      opacity: 1;
      color: $gray-dark;
      border: 1px solid $gray2;
      background: $white;
      max-height: 50px;
      margin-bottom: 0;
    }

    [type='file'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0 none;
    }

    &.is-focus {
      outline: 1px solid $dark;
    }
  }

  &--zipcode {
    .d-zipcode {
      display: block;
      position: relative;

      &-cell {
        display: block;
        padding-right: 92px;
        margin-bottom: $bottom-space;
        box-sizing: border-box;
      }

      .btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .d-br {
      display: block;
      margin-top: 5px;
    }

    @include mq(small) {
      .d-zipcode {
        display: block;
        position: relative;
        padding-left: percentage(329/908);

        &-cell {
          position: absolute;
          top: 0;
          left: 0;
          width: percentage(319/908);
          margin-bottom: 0;
        }
      }
    }

    @include mq(large) {
      .d-br {
        margin-top: 10px;
      }
    }
  }

  &--basic {
    margin-top: rem(10);

    @include mq(large) {
      display: flex;

      .pad {
        width: 76.5%;
      }

      .input-g {
        &__pad {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }

  &--address {
    .input-g {
      &__pad {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  &--experience {
    @include mq(large) {
      .input-date {
        max-width: 173px;
      }
    }
  }

  &--educations {
    @include mq(large) {
      [data-item='date1'],
      [data-item='date2'] {
        width: calc(30% - 8px);
      }

      [data-item='state'] {
        width: calc(20% - 8px);
      }

      // 고등학교
      [data-cetagory='highschool'] {
        [data-item='school'] {
          width: calc(25% - 8px);
        }

        [data-item='grade'] {
          margin-left: 0;
        }

        [data-item='date1'],
        [data-item='date2'] {
          width: calc(20% - 8px);
        }

        [data-item='state'] {
          width: calc(15% - 8px);
        }
      }

      // 검정고시
      [data-cetagory='examination'] {
        [data-item='date1'] {
          width: calc(80% - 2px);
        }
      }

      // 대학교
      [data-cetagory='university'] {
        [data-item='school'] {
          width: calc(40% - 8px);
        }

        [data-item='grade'] {
          margin-left: 0;
        }

        .form-g:nth-child(n + 4) {
          margin-top: rem(10);
        }
      }
    }
  }
}
