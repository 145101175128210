// -----------------------------------------------------------------------------
// Form > Textarea
// -----------------------------------------------------------------------------

.ta {
  position: relative;

  &-counter-box {
    position: relative;
    padding-bottom: 32px;
    border: 1px solid $gray2;
    transition: border-color 0.3s;
    background-color: $white;

    &:hover,
    &.is-active {
      border-color: $gray9;
    }

    textarea {
      border: 0;
      padding-top: rem(10);
      padding-bottom: 0;
    }

    .is-disabled & {
      background-color: $gray0;
    }
  }

  &-counter {
    position: absolute;
    right: 15px;
    bottom: 12px;
    font-size: 13px;
    color: $gray6;
  }

  &-box {
    textarea {
      border: 0;
    }

    .ta-counter {
      left: 15px;
      right: auto;
      bottom: 10px;
    }
  }

  &-file {
    border-top: 1px solid $silver;
    margin: 0 $input-padding-lr;
    padding: 10px 0;
    bottom: 0;
    font-size: 14px;
    text-align: right;

    .btn-upload {
      display: inline-block;
      // @include icon('photo') {
      //   padding-right: 5px;
      //   vertical-align: middle;
      // }
    }

    [type=file] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0 none;
    }

    &.is-focus {
      .btn-upload {
        outline: 1px dotted $gray;
      }
    }
  }

  &-attached {
    position: relative;
    font-size: 14px;
    margin-top: 10px;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
    background: $gray0;

    .btn-delete {
      position: absolute;
      top: 0;
      right: 0;
      padding: 13px 10px;
      font-size: 11px;
      @include icon('close');
    }
  }
}
