// -----------------------------------------------------------------------------
// Flatpickr - Dateiime Picker
// -----------------------------------------------------------------------------

@import 'flatpickr/dist/flatpickr.min.css';

.flatpickr {
  $btn-size: 32px;
  $btn-margin: 5px;
  $calendar-size: ($btn-size * 7) + ($btn-margin * 14);

  position: relative;

  &-input {
    &.active {
      border-color: $dark !important;
    }
  }

  &-btn {
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 10px 10px;

    // @include icon('calendar') {
    //   font-size: 18px;
    // }
  }

  &-calendar {
    box-sizing: border-box;
    width: auto;
    margin: 5px 0;
    padding: 0 10px;
    border: 1px solid $gray5;
    border-radius: 0;

    &.arrowTop,
    &.arrowBottom {
      &::before,
      &::after {
        display: none;
      }
    }

    span.flatpickr-weekday {
      font-size: 14px;
      color: $gray7;
    }
  }

  &-months {
    height: 40px;
    padding-top: 10px;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      top: 10px;

      &::before {
        font-size: 18px;
      }
      svg {
        width: 0;
        height: 0;
      }
    }
    .flatpickr-prev-month {
      @include icon(arrow-left);
    }
    .flatpickr-next-month {
      @include icon(arrow-right);
    }
  }

  &-title {
    display: block;
    width: 100%;
    padding-top: 4px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }

  &-month {
    display: none;
  }

  &-days {
    width: $calendar-size;
    padding-bottom: 10px;

    .dayContainer {
      width: $calendar-size;
      min-width: $calendar-size;
      max-width: $calendar-size;
    }
  }

  &-day {
    max-width: $btn-size;
    height: $btn-size;
    margin: 3px $btn-margin;
    font-size: 13px;
    line-height: $btn-size;
    color: $gray8;

    &:hover,
    &:focus {
      color: $color-primary-pale;
      background: #f0f0f0;
    }

    &.today {
      border-color: $gray3;
      background: transparent;
      color: $gray8;

      &:hover,
      &:focus {
        color: $gray8;
        border-color: $gray3;
        background: transparent;
      }
    }

    &.selected {
      color: $white;
      border-color: $color-primary-pale;
      background: $color-primary-pale;

      &:hover,
      &:focus {
        color: $white;
        border-color: $color-primary-pale;
        background: $color-primary-pale;
      }
    }

    &.prevMonthDay,
    &.nextMonthDay {
      font-size: 0;
      height: 0;
      border: transparent;
      background: transparent;

      &:hover,
      &:focus {
        border: transparent;
        background: transparent;
      }
    }
  }
}
