// -----------------------------------------------------------------------------
// Table
// -----------------------------------------------------------------------------

@mixin table-style {
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid $gray2;
  border-left: 1px solid $gray2;
  border-right: 1px solid $gray2;

  th,
  td {
    padding-left: $list-padding-lr;
    padding-right: $list-padding-lr;
    border-top: 1px solid $gray2;
    border-left: 1px solid $gray2;
    text-align: left;
    font-size: 15px;
    @include break-word;

    &:first-child {
      border-left: 0;
    }
  }

  th {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    background-color: $list-bg-color;
    font-weight: normal;
  }

  td {
    padding-top: 0.9em;
    padding-bottom: 0.9em;
  }
}

.table {
  @include table-style;

  caption.a11y {
    position: relative !important;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0
  }

  // Common Element
  // -----------------------------------------------------------------------------
  &-wrap {
    overflow-x: auto;
  }

  // Set Colgorup Width
  // -----------------------------------------------------------------------------
  &-cell {
    @for $i from 1 through 10 {
      &-1of#{$i} {
        width: percentage(1 / $i);
      }
    }

    &-3of5 {
      width: 60%;
    }
    &-2of3 {
      width: 66.66%;
    }
    &-3of4 {
      width: 75%;
    }
    &-4of5 {
      width: 80%;
    }
    &-5of6 {
      width: 83.33%;
    }
  }

  // Text Style
  // -----------------------------------------------------------------------------
  .talign-l {
    text-align: left;
  }
  .talign-c {
    text-align: center;
  }
  .talign-r {
    text-align: right;
  }
  .bg-gray {
    background: $gray0;
  }
  .bg-em {
    padding: 1rem 0;
    background-color: $gray1;
    border-bottom: $gray2;
    color: $color-primary-pale;
    font-weight: bold;
  }

  .noitem {
    padding: 25px 0;
    text-align: center;

    @include mq(medium) {
      padding: 50px 0;
    }
  }

  // Type Variation
  // ---------------------------------------------------------------------------
  // &--list {
  //   @include table-style;

  //   th {
  //     border-bottom: 0;

  //     &:first-child + th,
  //     &:first-child + td {
  //       border-left-color: $gray2;
  //     }
  //   }
  // }

  &--board {
    @include table-style;
    max-width: 900px;
    margin: 0 auto;

    th,
    td {
      text-align: center;
    }

    a {
      word-break: break-all;
    }

    .linkline {
      display: block;
    }
  }

  // &--value {
  //   td {
  //     text-align: right;

  //     &:first-child {
  //       text-align: center;
  //     }
  //     span {
  //       display: none;
  //     }
  //   }
  // }

  &--spec {
    border-bottom: 0;
    max-width: 890px;
    margin: 0 auto;
    border: none;

    th,
    td {
      text-align: left;
      padding: 16px 15px;
    }

    th {
      background-color: transparent;
      border-color: $gray9;
      border-bottom: 1px solid $gray9;
    }

    td {
      border-left: 10px solid $white;
      border-bottom: 1px solid $gray2;
      color: $gray6;
    }
  }

  @include mq($until: large) {
    &--board {
      .col-download {
        width: 80px;
      }

      .download {
        padding: 0;

        &::before {
          display: none;
        }

        .btn-download {
          border: none;
          width: 40px;
          height: 40px;
          padding: 0;
          margin: 0 auto;
          line-height: 40px;

          &::before {
            display: none;
          }

          .btn__text {
            position: absolute !important;
            overflow: hidden;
            height: 1px;
            width: 1px;
            clip: rect(1px, 1px, 1px, 1px);
          }

          &:hover,
          &:focus {
            color: $gray9;
          }
        }
      }
    }
  }

  @include mq($until: medium) {
    &--complex {
      min-width: 720px;
    }

    // &--list {
    //   border-top: 1px solid darken($gray2, 10%);
    //   table-layout: auto;

    //   tr,
    //   th,
    //   td {
    //     display: block;
    //     border: 0;
    //     text-align: left;
    //   }
    //   tr {
    //     border-top: 1px solid $gray2;
    //     &:first-child {
    //       border-top: 0;
    //     }
    //   }
    //   th {
    //     width: auto;
    //     padding-left: 12px;
    //     font-size: 14px;
    //   }
    //   td {
    //     padding-left: 12px;
    //   }
    //   br {
    //     display: none;
    //   }
    // }

    &--board {
      border: none;

      &,
      tbody,
      tr,
      td {
        display: block;
        width: auto;
      }

      td {
        font-size: rem(16);
      }

      tr {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: rem(6);
        border-bottom: 1px solid $gray2;

        & + tr {
          padding-top: rem(14);
        }
      }

      colgroup,
      thead,
      .no {
        display: none;
      }

      td {
        text-align: left;
        border: 0;
        padding: 0;

        & + td {
          position: relative;
          margin-left: rem(8);
          padding-left: rem(11);

          &::before {
            position: absolute;
            top: 6px;
            left: 0;
            content: '';
            width: 1px;
            height: 15px;
            background-color: $gray2;
          }
        }
      }

      .title {
        order: 1;
        width: calc(100% - 40px);
        margin-left: 0;
        padding: rem(10) 0;
        line-height: 1.4;

        &::before {
          display: none;
        }
      }

      .linkline {
        display: block;
      }

      .download {
        position: absolute;
        right: 0;
        bottom: 17px;
        padding-left: 0;
        // padding-bottom: rem(17);
      }

      .btn-download {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0;
      }
    }

    // &--value {
    //   thead {
    //     display: none;
    //   }
    //   tr {
    //     display: block;
    //     position: relative;
    //     padding-left: percentage(1/3);
    //   }
    //   th,
    //   td {
    //     display: block;
    //     box-sizing: border-box;

    //     &:first-child {
    //       position: absolute;
    //       left: 0;
    //       top: 0;
    //       width: percentage(1/3);
    //     }
    //   }

    //   td {
    //     text-align: left;

    //     span {
    //       display: inline-block;
    //       margin-right: 2rem;
    //     }
    //   }
    // }
  }

  @include mq($until: small) {
    &--spec {
      display: block;
      border-bottom: 1px solid $gray2;

      tbody,
      tr,
      th,
      td {
        display: block;
      }

      tr {
        padding: rem(11) 0;
        border-top: 1px solid $gray2;
      }

      th {
        margin-bottom: rem(5);
      }

      th,
      td {
        padding: 0 rem(10);
        border: none;
      }
    }
  }

  @include mq(large) {
    &--board {
      .col-download {
        width: 166px;
      }

      .download {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .btn-download {
        [lang='en'] & {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}
