// -----------------------------------------------------------------------------
// Indicator
// -----------------------------------------------------------------------------

.indicator {
  display: block;
  font-size: 0;
  margin: 0 auto;
  text-align: center;

  button {
    width: 30px;
    height: 30px;

    &:before {
      content: '';
      overflow: hidden;
      display: block;
      width: 12px;
      height: 12px;
      margin: auto;
      border: 1px solid $gray8;
      box-sizing: border-box;
      color: transparent;
      border-radius: 50%;
      box-sizing: border-box;
      transition: background-color 0.2s, opacity 0.2s;
    }
    &:hover,
    &:focus {
      &:before {
        background-color: $gray8;
      }
    }
  }
  li {
    display: inline-block;
    vertical-align: middle;

    &.is-current {
      button {
        &:before {
          background-color: $gray8;
        }
      }
    }
  }
  &--invert {
    li {
      &.is-current {
        button {
          &:before {
            background-color: $white;
          }
        }
      }
    }
    button {
      &:before {
        border-color: $white;

      }
      &:hover,
      &:focus {
        &:before {
          background-color: $white;
        }
      }
    }
  }
  @include mq(medium) {
    & {
      button {
        &:before {
          opacity: 0.4;

        }
        &:hover,
        &:focus {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}
