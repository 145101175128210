// -----------------------------------------------------------------------------
// site-search
// -----------------------------------------------------------------------------

.search {
  box-sizing: border-box;
  height: 100%;
  padding-bottom: rem(40);
  // overflow: scroll;

  &__input {
    border: none;
    width: 100%;
    max-height: none;
    padding: rem(22) rem(34) rem(22) rem(0);
    font-size: rem(16);
    border-bottom: 1px solid rgba($gray9, 0.2);
  }

  &__btn {
    position: absolute;
    right: -16px;
    top: 3px;
    padding: 16px;

    .icon-search {
      font-size: rem(24);
    }
  }

  &__recommend {
    margin-top: rem(30);

    .tag {
      margin-top: rem(10);
    }
  }

  &__tip {
    display: block;
    margin-bottom: rem(10);
    color: $gray5;
    font-size: rem(16);
  }

  @include mq(large) {
    &__wrap {
      &.l-narrow {
        width: 50%;
      }
    }

    &__input {
      //@IE
      max-height: $header-height-lg;
      border-bottom-color: rgba($gray9, 0);
      // border-bottom: none;
    }
  }
}
