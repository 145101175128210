// -----------------------------------------------------------------------------
// Form > range-bar
// @dependency: vendor/nouislider
// -----------------------------------------------------------------------------

.range-bar {
  margin-bottom: 50px;

  .slider-range {
    margin-bottom: 50px;
  }

  .input-g {
    flex: 1;

    & + .input-g {
      margin-left: 5px;
    }
  }

  &.is-too-closely {
    .noUi-handle-lower:after {
      left: -34px;
    }

    .noUi-handle-upper:after {
      left: -14px;
    }

    &.is-min {
      .noUi-handle-upper:after {
        left: -8px;
      }
    }

    &.is-max {
      .noUi-handle-lower:after {
        left: -48px;
      }
    }
  }

  &.is-min {
    .noUi-handle-lower:after {
      left: -20px;
    }
  }

  &.is-max {
    .noUi-handle-upper:after {
      left: -38px;
    }
  }
}
