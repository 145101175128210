// -----------------------------------------------------------------------------
// Notyf - Notification plugin
// https://github.com/caroso1222/notyf
// -----------------------------------------------------------------------------

// @import 'notyf/notyf.min.css';

@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(25%);
  }
}

$toast-padding: 2.5em;

.notyf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  box-sizing: border-box;
  font-size: rem(16);

  &__icon {
    display: none;
  }

  &__toast {
    display: block;
    overflow: hidden;
    pointer-events: auto;
    animation: notyf-fadeinup 0.3s ease-in forwards;
    position: relative;
    padding: 0 $toast-padding;
    border-radius: 2px;
    width: 100%;
    transform: translateY(25%);
    box-sizing: border-box;
    flex-shrink: 0;
    text-align: center;

    &--disappear {
      transform: translateY(0);
      animation: notyf-fadeoutdown 0.3s forwards;
      animation-delay: 0.25s;

      .notyf {
        &__message, &__icon {
          animation: notyf-fadeoutdown 0.3s forwards;
          opacity: 1;
          transform: translateY(0);
        }

        &__dismiss {
          animation: notyf-fadeoutright 0.3s forwards;
          opacity: 1;
          transform: translateX(0);
        }
      }

      .notyf__message {
        animation-delay: 0.05s;
      }
    }

    &--upper {
      margin-bottom: 20px;
    }

    &--dismissible {

      .notyf__wrapper {
        padding-right: 30px;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    padding-top: 28px;
    padding-bottom: 28px;
    padding-right: 15px;
    border-radius: 3px;
    position: relative;
    z-index: 10;
    justify-content: center;
  }

  &__icon {
    width: 22px;
    text-align: center;
    font-size: 1.3em;
    opacity: 0;
    animation: notyf-fadeinup 0.3s forwards;
    animation-delay: 0.3s;
    margin-right: 13px;

    .icon {
      vertical-align: middle;
    }
  }

  &__dismiss {
    position: absolute;
    top: 0;
    right: 30px;
    height: 100%;
    width: 70px;
    margin-right: -$toast-padding;
    animation: notyf-fadeinleft 0.3s forwards;
    animation-delay: 0.35s;
    opacity: 0;
  }

  &__dismiss-btn {
    border: none;
    cursor: pointer;
    transition: opacity 0.2s ease, background-color 0.2s ease;
    outline: none;
    height: 100%;
    width: 100%;

    &:after, &:before {
      content: "";
      background: white;
      height: 16px;
      width: 2px;
      position: absolute;
      left: calc(50% - 1px);
      top: calc(50% - 5px);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:active {
      opacity: 0.8;
    }
  }

  &__message {
    vertical-align: middle;
    position: relative;
    opacity: 0;
    animation: notyf-fadeinup 0.3s forwards;
    animation-delay: 0.25s;
    line-height: 1.5em;
  }
}

/* Small screens */
@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;

    &__toast {
      max-width: initial;
      border-radius: 0;
      width: 100%;
    }

    &__dismiss {
      right: 10px;
    }
  }
}
