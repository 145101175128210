// -----------------------------------------------------------------------------
// Tag
// -----------------------------------------------------------------------------

.tag {
  display: inline-block;
  padding: 4px 10px;
  border: 1px solid $gray2;
  color: $gray-light;
  font-size: 1.6rem;
  border-radius: 4px;
  background-color: $gray0;
  transition: 0.2s;
  margin-right: 4px;
  margin-top: 10px;

  &:before {
    content: '#';
  }

  &:hover,
  &:focus {
    color: $color-secondary-dark;
    border-color: $color-secondary-dark;
    background-color: transparent;
  }
}

.tagtext {
  margin-right: 4px;
  color: $gray6;
  font-size: rem(15);
  white-space: pre;
  line-height: 1.6;

  &-box {
    line-height: 1.3;
  }

  &::before {
    content: '#';
  }
}
