// -----------------------------------------------------------------------------
// Pagination
// -----------------------------------------------------------------------------

@mixin small-pn {
  .page {
    min-width: 35px;
    height: 35px;
    padding: 0 3px;
    line-height: 38px;
  }
  .prev,
  .next {
    height: 30px;
    width: 30px;
    min-width: 30px;
    line-height: 30px;

    &::after {
      margin: 4px;
    }
  }
}

$size: 36px;
%btn-style {
  display: inline-block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: $size;
  min-width: $size;
  line-height: $size - 2px;

  .page__num {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -6px;
      width: calc(100% + 12px);
      max-width: $size - 4px;
      height: 2px;
      background-color: $color-secondary-dark;
      transform: scale(0, 1) translateX(-50%);
      transition: 0.2s $ease;
      transform-origin: 0% 0%;
    }
  }
}
%btn-hover {
  color: $color-secondary-dark;

  .page__num {
    &::after {
      transform: scale(1, 1) translateX(-50%);
    }
  }
}

.pagination {
  // margin-top: 5rem;
  font-size: 0;
  text-align: center;
  $span-h: 28px;

  .page {
    position: relative;
    box-sizing: border-box;
    @extend %btn-style;
    text-decoration: none;
    font-size: 1.5rem;
    transition: color 0.2s;
    color: $color-primary-light;

    &.is-current {
      color: $color-primary;

      .page__num {
        &::after {
          background-color: $color-primary;
          transform: scale(1, 1) translateX(-50%);
        }
      }
    }
  }

  .prev,
  .next {
    @extend %btn-style;
    width: 40px;
    text-align: center;
    font-size: 1.5rem;

    &.is-disabled {
      &::before {
        opacity: 0.3;
      }
    }
  }

  a {
    &.page {
      &:hover,
      &:focus {
        @extend %btn-hover;
      }
    }
  }

  .prev {
    @include icon(arrow-left-bold);
  }
  .next {
    @include icon(arrow-right-bold);
  }

  @include mq($until: medium) {
    .no-touchevents & {
      @include small-pn;
    }
    & {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  @include mq($until: $w-small - 1px) {
    @include small-pn;
  }
}
